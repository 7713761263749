import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  EventActions,
  EventNames,
} from '../../../../shared/constant/Analytics';
import fetchClient from '../../../../utilities/hooks/useFetch/fetchClient';
import useTracking from '../../../../utilities/hooks/useTracking';
import { toSentenceCaseMultipleWords } from '../../../utilities/common/Display';
import decodeCode from '../../../utilities/common/Encryption';
import { Breadcrumbs } from '../../app-components/Breadcrumbs/Breadcrumbs';
import { TabPanel, Tabs } from '../../components/Tabs';
import { AssignmentsList } from './Assignments/AssignmentsList';
import CreditDetailsCard from './BookSessionModal/CreditDetailsCard/CreditDetailsCard';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
import ClientDetailsContext from './context/ClientDetailsContext';
import { HelplineLogsList } from './HelplineLogs/HelplineLogsList';
import HelplineNoEAPAccess from './HelplineLogs/HelplineNoEAPAccess';
import { useClientDetailsV2 } from './hooks/UseClientDetails';
import PersonalInsights from './PersonalInsights';
import { SessionsList } from './Sessions/SessionsList';
import UserEmergencyContactCard from './UserEmergencyContactCard';
import UserGoalsCard from './UserGoalsCard';
import UserInfoCard from './UserInfoCard';
import UserNotesCard from './UserNotesCard';
import UserPresentingIssuesCard from './UserPresentingIssuesCard';
import UserRiskLevelCard from './UserRiskLevelCard';
import UserTagsCard from './UserTagsCard';

const TabsList = [
  { key: 1, label: 'SESSIONS' },
  { key: 2, label: 'ASSIGNMENTS' },
  { key: 3, label: 'PERSONAL INSIGHTS_QUIZ' },
  { key: 4, label: 'HELPLINE_LOGS' },
];

const InstrumentationKeysForTabs = {
  '1': 'sessions_tab',
  '2': 'assignment_tab',
  '3': 'personal_insights_tab',
  '4': 'helpline_tab',
};

const defaultComponentsOrder = [
  'profile',
  'bookSession',
  'notes',
  'goals',
  'presentingIssue',
  'risk',
  'emergencyContact',
  'tags',
];
const ClientComponents = {
  profile: UserInfoCard,
  bookSession: null,
  notes: UserNotesCard,
  goals: UserGoalsCard,
  presentingIssue: UserPresentingIssuesCard,
  risk: UserRiskLevelCard,
  emergencyContact: UserEmergencyContactCard,
  tags: UserTagsCard,
};

export default function ClientDetailsV2() {
  const { clientId, serviceType } = useParams();
  const decodedClientId = decodeCode(clientId as string).toString();
  const [showNoEAPAccessModal, setShowNoEAPAccessModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();
  const { track } = useTracking();

  const { data: clientDetailData, isLoading: isClientDetailLoading } =
    useClientDetailsV2(decodedClientId, serviceType);

  const isLoading = isClientDetailLoading;

  const clientName =
    toSentenceCaseMultipleWords(
      clientDetailData?.profile?.name?.trim() ?? '',
    ) ||
    clientId ||
    'N/A';

  const clientDetailsContextValues = useMemo(
    () => ({
      clientDetailData,
      isLoading,
      decodedClientId,
      encodedClientId: clientId,
      serviceType,
      clientName,
      refreshData: () => fetchClient.invalidateQueries(['provider']),
    }),
    [clientDetailData, isLoading, decodedClientId, serviceType],
  );

  const ComponentsOrder = (clientDetailData?.order ??
    defaultComponentsOrder) as (keyof typeof ClientComponents)[];

  return (
    <ClientDetailsContext.Provider value={clientDetailsContextValues}>
      <section className="p-6 box-border grid grid-cols-12 gap-6">
        <section className="col-span-12 flex justify-end">
          {clientDetailData ? (
            <Breadcrumbs
              className="w-full"
              list={[
                { title: t('clients'), link: '/clients' },
                { title: clientName },
                { title: t(TabsList.at(selectedIndex)?.label || 'SESSIONS') },
              ]}
            />
          ) : null}
        </section>
        <section className="col-span-4">
          <section className="flex flex-col gap-4">
            {ComponentsOrder.map((comp) => {
              const Component = ClientComponents?.[comp];
              return Component ? <Component key={comp} /> : null;
            })}
          </section>
        </section>
        <section className="col-span-8 gap-6">
          <section className="flex flex-col gap-4">
            {isLoading ? (
              <ClientOverviewCardLoader variant="credits" />
            ) : (
              <CreditDetailsCard viewDetailEventLabel="client_view" />
            )}

            {isLoading ? (
              <ClientOverviewCardLoader variant="tabs" hideCardLayout />
            ) : (
              <>
                <div className="text-black text-displayXs font-semibold">
                  {t('MORE_ABOUT_CLIENT_HEADER', { client_name: clientName })}
                </div>
                <Tabs
                  selectedIndex={selectedIndex}
                  setSelectedIndex={(newIndex) => {
                    const instrumentationKeyForNewTab =
                      InstrumentationKeysForTabs[
                        newIndex.toString() as keyof typeof InstrumentationKeysForTabs
                      ];
                    track(EventNames.clientView, {
                      eventAction: EventActions.click,
                      eventCategory: instrumentationKeyForNewTab,
                      eventLabel: instrumentationKeyForNewTab,
                    });
                    if (
                      newIndex === 3 &&
                      !clientDetailData?.profile?.isEAPenabled
                    ) {
                      setShowNoEAPAccessModal(true);
                    } else {
                      setSelectedIndex(newIndex);
                    }
                  }}
                  list={TabsList}
                >
                  <TabPanel index={1}>
                    <SessionsList clientId={decodedClientId} />
                  </TabPanel>
                  <TabPanel index={2}>
                    <AssignmentsList clientId={decodedClientId} />
                  </TabPanel>
                  <TabPanel index={3}>
                    {serviceType && (
                      <PersonalInsights
                        clientId={decodedClientId}
                        serviceType={serviceType}
                      />
                    )}
                  </TabPanel>
                  <TabPanel index={4} key={4}>
                    {decodedClientId && (
                      <HelplineLogsList clientId={decodedClientId} />
                    )}
                  </TabPanel>
                </Tabs>
              </>
            )}
          </section>
        </section>
      </section>
      <HelplineNoEAPAccess
        open={showNoEAPAccessModal}
        handleClose={() => setShowNoEAPAccessModal(false)}
      />
    </ClientDetailsContext.Provider>
  );
}
