import { Placement, VirtualElement } from '@popperjs/core';
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import useOutsideClickHandler from '../../../utilities/hooks/useOutsideClickHandler';

interface IPopperProps {
  anchorEl: Element | VirtualElement | null;
  handleClose: () => void;
  children: ReactNode;
  placement?: Placement;
}
function Popper({
  anchorEl,
  handleClose,
  children,
  placement = 'left',
}: IPopperProps) {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const popperRef = useRef<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(anchorEl, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  useOutsideClickHandler(popperRef, () => {
    handleClose();
  });

  useEffect(() => {
    if (anchorEl) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [anchorEl]);

  return (
    anchorEl && (
      <div className="z-30">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}
          className="fixed inset-0 bg-black/5"
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}
          ref={(node) => {
            setPopperElement(node);
            popperRef.current = node;
          }}
          style={styles.popper}
          className="z-50"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes.popper}
        >
          {children}
        </motion.div>
      </div>
    )
  );
}

export default Popper;
