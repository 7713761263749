import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { Dispatch, SetStateAction, useContext } from 'react';
import { ReactComponent as ChevronDown } from '../../../assets/ChevronDown.svg';
// import { ReactComponent as GoogleIcon } from '../../../assets/GoogleIcon.svg';
import { ReactComponent as IntellectOnlyLogo } from '../../../assets/IntellectOnlyLogo.svg';
// import { ReactComponent as OutlookIcon } from '../../../assets/OutlookIcon.svg';
import AvailabilityMeetingFilterBox from '../../app-components/AvailabilityCalendar/AvailabilityMeetingFilterBox';
// import { Button } from '../../components/Button';
import CheckboxWithLabel from '../../components/CheckboxWithLabel';
import { DatePicker } from '../../components/DatePicker';
import AvailabilityContext from './context/AvailabilityContext';
import { ProviderRole } from '../../../shared/constant/Common';

interface IAvailabilityFiltersAndActions {
  calendarSelectedDate: Date;
  setCalendarSelectedDate: Dispatch<SetStateAction<Date>>;
  filters: {
    virtual: boolean;
    inPerson: string[];
    onSite: boolean;
  };
  setFilters: Dispatch<
    SetStateAction<{
      virtual: boolean;
      inPerson: string[];
      onSite: boolean;
    }>
  >;
}

function AvailabilityFiltersAndActions({
  calendarSelectedDate,
  setCalendarSelectedDate,
  filters,
  setFilters,
}: IAvailabilityFiltersAndActions) {
  const { clinics, services } = useContext(AvailabilityContext);

  return (
    <section className="flex-3 flex flex-col gap-4">
      <section>
        <DatePicker
          key={calendarSelectedDate?.toString()}
          selected={calendarSelectedDate}
          onSelect={(newDate) => newDate && setCalendarSelectedDate(newDate)}
          classNames={{
            root: 'bg-transparent z-2 text-xs',
            day: '[&_button]:w-8 [&_button]:h-8 bg-transparent rounded-[50%] ',
          }}
        />
      </section>
      <section>
        <span className="text-sm font-bold">My calendars</span>
        <Disclosure as="div" className="p-4 tex-xs" defaultOpen>
          <DisclosureButton className="group flex w-full gap-1 items-center justify-start text-xs font-medium">
            <ChevronDown className="text-gray-400 group-data-[hover]:text-gray-400/50 group-data-[open]:rotate-180 transition-all" />
            <IntellectOnlyLogo />
            <span>Intellect calendar</span>
          </DisclosureButton>
          <DisclosurePanel>
            <AvailabilityMeetingFilterBox className="mt-2">
              <CheckboxWithLabel
                label="Virtual"
                classNames={{
                  root: 'font-medium',
                  checkBox: 'data-[checked]:bg-cyan-500',
                }}
                checked={filters?.virtual}
                handleOnChange={(checked) =>
                  setFilters((prev) => ({ ...prev, virtual: checked }))
                }
              />
            </AvailabilityMeetingFilterBox>
            {clinics?.length ? (
              <Disclosure as="div" className="px-4 tex-xs" defaultOpen>
                <DisclosureButton className="group flex w-full gap-1 items-center justify-start text-xs font-medium">
                  <ChevronDown className="text-gray-400 group-data-[hover]:text-gray-400/50 group-data-[open]:rotate-180 transition-all" />
                  <AvailabilityMeetingFilterBox className="mt-0 p-0">
                    <CheckboxWithLabel
                      label="In-person"
                      classNames={{
                        root: 'font-medium',
                        checkBox: 'data-[checked]:bg-purple-500',
                      }}
                      checked={!!filters?.inPerson?.length}
                      handleOnChange={(checked) =>
                        setFilters((prev) => ({
                          ...prev,
                          inPerson: checked
                            ? clinics?.map((item) => item?.id) ?? []
                            : [],
                        }))
                      }
                    />
                  </AvailabilityMeetingFilterBox>
                </DisclosureButton>
                <DisclosurePanel>
                  <AvailabilityMeetingFilterBox>
                    {clinics?.map((item) => (
                      <CheckboxWithLabel
                        key={item?.id}
                        label={item?.name}
                        classNames={{
                          root: 'font-medium',
                          checkBox: 'data-[checked]:bg-purple-500',
                          label: 'truncate max-w-24',
                        }}
                        checked={filters?.inPerson?.includes(item?.id)}
                        handleOnChange={(checked) =>
                          setFilters((prev) => ({
                            ...prev,
                            inPerson: checked
                              ? [...(prev?.inPerson ?? []), item?.id]
                              : [
                                  ...(prev?.inPerson?.filter(
                                    (locs) => locs !== item?.id,
                                  ) ?? []),
                                ],
                          }))
                        }
                      />
                    ))}
                  </AvailabilityMeetingFilterBox>
                </DisclosurePanel>
              </Disclosure>
            ) : null}

            {services?.includes(ProviderRole.ONSITE) ? (
              <AvailabilityMeetingFilterBox>
                <CheckboxWithLabel
                  label="Onsite"
                  classNames={{
                    root: 'font-medium',
                    checkBox: 'data-[checked]:bg-warning-500',
                  }}
                  checked={filters?.onSite}
                  handleOnChange={(checked) =>
                    setFilters((prev) => ({ ...prev, onSite: checked }))
                  }
                />
              </AvailabilityMeetingFilterBox>
            ) : null}
          </DisclosurePanel>
        </Disclosure>
      </section>
      {/* Will be up in future */}
      {/* <section>
        <span className="text-sm font-bold">Connect a calendar</span>

        <section className="mt-2 flex flex-col gap-2">
          <Button variant="secondary" className="w-full">
            <div className="flex justify-center items-center gap-1">
              <GoogleIcon />
              Google
            </div>
          </Button>
          <Button variant="secondary" className="w-full">
            <div className="flex justify-center items-center gap-1">
              <OutlookIcon />
              Outlook
            </div>
          </Button>
        </section>
      </section> */}
    </section>
  );
}

export default AvailabilityFiltersAndActions;
