import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../../shared/constant/Analytics';
import useTracking from '../../../../utilities/hooks/useTracking';
import TimeRangePicker from '../../app-components/TimeRangePicker/TimeRangePicker';
import { Select } from '../../components/Select';
import {
  DaysOfWeek,
  virtualLocationKey,
} from '../../../shared/constant/Provider';
import { getSessionTypeOptions } from '../../../utilities/common/Provider';
import { Button } from '../../components/Button';
import { ReactComponent as PlusIcon } from '../../../assets/Plus.svg';
import { ReactComponent as TrashIcon } from '../../../assets/trash.svg';
import {
  IProviderDaySlot,
  IProviderServicesAndClinic,
  ISlotRange,
} from '../../../shared/types/Availability';
import Switch from '../../components/Switch';

interface WorkingHoursSlotProps {
  dayCode: string;
  data: IProviderDaySlot;
  onRemoveSlot: (day: string, removedSlotIndex: number) => void;
  onSlotsChange: (day: string, index: number, updatedSlot: ISlotRange) => void;
  onAddSlot: () => void;
  onSlotsClear: (day: string) => void;
  providerServices: IProviderServicesAndClinic;
  slotsWithConflicts: number[];
}

function WorkingHoursDay({
  dayCode,
  data,
  onRemoveSlot,
  onAddSlot,
  onSlotsChange,
  onSlotsClear,
  providerServices,
  slotsWithConflicts,
}: WorkingHoursSlotProps) {
  const { t } = useTranslation();
  const { track } = useTracking();

  const onDayToggleChange = (isChecked: boolean) => {
    if (isChecked) {
      onAddSlot();
    } else {
      onSlotsClear(data?.day || '');
    }
    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.day,
      eventLabel: moment.weekdaysShort(Number(data?.day)),
      featureVersion: 'v1',
      eventValue: isChecked,
    });
  };

  const slotsRangeList = [...(data?.slotsRange || [])];

  return (
    <div
      className={twMerge(
        'flex items-start gap-12 my-2 rounded-lg transition-colors',
        slotsWithConflicts.length && 'bg-error-50 border border-error-100',
      )}
    >
      <div className="flex items-center gap-3 w-32 p-3">
        <Switch
          onChange={(isChecked) => onDayToggleChange(isChecked)}
          checked={Boolean(data.slotsRange.length)}
        />
        <div
          className={twMerge(
            'text-sm text-gray-600 transition-colors',
            slotsWithConflicts.length && 'text-error-500',
          )}
        >
          {t(DaysOfWeek[dayCode as keyof typeof DaysOfWeek])}
        </div>
      </div>
      <div className="grid grid-cols-4 items-center py-3 gap-x-8 gap-y-3">
        {slotsRangeList.length ? (
          slotsRangeList.map((slot, index) => {
            const timeRange = slot.slots.length
              ? {
                  startTime: slot.slots[0].toString(),
                  endTime: slot.slots[1].toString(),
                }
              : undefined;

            const rowLocation =
              slot.locationId === virtualLocationKey
                ? slot.locationId
                : providerServices.clinics.find(
                    (loc) => loc.id === slot?.locationId,
                  )?.id;

            return (
              <React.Fragment key={JSON.stringify(slot)}>
                <div className="col-span-3 flex items-center gap-5">
                  <Select
                    fullWidth
                    selected={rowLocation}
                    options={getSessionTypeOptions({
                      ...providerServices,
                      dayCode,
                    })}
                    onSelect={(updatedLocation) => {
                      onSlotsChange(dayCode, index, {
                        ...slot,
                        locationId: updatedLocation,
                      });
                    }}
                    placeholder="Select location"
                    className={
                      slotsWithConflicts.length ? 'border-error-300' : ''
                    }
                  />
                  <TimeRangePicker
                    value={timeRange}
                    onChange={({ startTime, endTime }) => {
                      onSlotsChange(dayCode, index, {
                        ...slot,
                        slots: [+startTime, +endTime],
                      });
                    }}
                    selectClassName={
                      slotsWithConflicts.length ? 'border-error-300' : ''
                    }
                  />
                </div>

                <div className="col-span-1 flex items-center gap-3">
                  <Button
                    variant="secondary"
                    onClick={() => onRemoveSlot(dayCode, index)}
                  >
                    <TrashIcon />
                  </Button>
                  {index === 0 ? (
                    <Button variant="secondary" onClick={() => onAddSlot()}>
                      <PlusIcon />
                    </Button>
                  ) : null}
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <div className="text-md text-gray-400 col-span-2">Unavailable</div>
        )}
      </div>
    </div>
  );
}

export default WorkingHoursDay;
