import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as DeleteTrashIcon } from '../../../assets/DeleteTrashIcon.svg';
import { ReactComponent as NoAvailabilityFoundIcon } from '../../../assets/NoAvailabilityFound.svg';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import {
  BlockingSlotsTypes,
  IProviderDaySlot,
  IProviderTransformedOverrides,
  ISlotRange,
  SlotsErrorCodes,
} from '../../../shared/types/Availability';
import {
  convertTo12HourFormat,
  findOverlappingSlots,
  sliceOverlappingSlots,
  slotToTime,
  transformSlotsRangeToOverridePayload,
  validateSlotsOverlap,
} from '../../../utilities/common/Date';
import { Button } from '../../components/Button';
import InfoTile from '../../components/InfoTile';
import NoDataFound from '../../components/NoDataFound';
import { Select } from '../../components/Select';
import AvailabilityContext from '../../screens/Availability/context/AvailabilityContext';
import { useAddOverride } from '../../screens/Availability/hooks/UseAvailabilities';
import AvailabilitySlotsPicker from './AvailabilitySlotsPicker';
import { overridesValidationSchema } from './schemas/ValiedateOverrides.schema';
import { AvailabilityBlockingVariants } from '../../../shared/constant/Availability';

interface OverridesFormType {
  day: string;
  slotsRange: ISlotRange[];
}

// Common handler to generate UI note for blocks, leaves & onsite
function generateBlockingUI(
  blockingSlots: ISlotRange[],
  type: BlockingSlotsTypes,
) {
  return blockingSlots?.map((block) => {
    const timeRange = {
      startTime: block?.slots?.[0],
      endTime: block?.slots?.[1] ? (block?.slots?.[1] ?? 0) + 1 : null,
    };

    const data = {
      start: !isNil(timeRange.startTime)
        ? convertTo12HourFormat(slotToTime(timeRange.startTime))
        : '',
      end: !isNil(timeRange.endTime)
        ? convertTo12HourFormat(slotToTime(+timeRange.endTime))
        : '',
    };
    const { variant, background, text, description } =
      AvailabilityBlockingVariants[type];
    return (
      <InfoTile
        lineClamp={2}
        variant={variant}
        className={twMerge('p-2', background)}
        contentClassName={text}
        showIcon
        content={`${description} ${data.start}-${data.end}`}
      />
    );
  });
}

function AvailabilityOverride({
  selectedDay,
  setHasUnsavedChanges,
  handleClose,
}: {
  selectedDay: string | null;
  setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
}) {
  // --------------- STATE MANAGEMENT ---------------
  const [selectedDaySlots, setSelectedDaySlots] = useState<
    IProviderTransformedOverrides | IProviderDaySlot | undefined
  >();

  const {
    workingHoursDayWiseSlots,
    overriddenSlots,
    clinics,
    blockedSlots,
    holidays,
    onsiteSlots,
    referchOverrides,
  } = useContext(AvailabilityContext);

  // --------------- NETWORK CALLS ---------------
  const handleOverrideApiSuccess = () => {
    toast.success('Availability updated');
    handleClose();
    referchOverrides();
  };

  const handleOverrideApiError = () => {
    toast.error('Error in updating Availability');
  };

  const { mutate: addOverride, isLoading } = useAddOverride(
    handleOverrideApiSuccess,
    handleOverrideApiError,
  );

  // --------------- Form Management ---------------
  const {
    reset,
    control,
    watch,
    trigger,
    formState: { errors, isDirty },
  } = useForm<OverridesFormType>({
    defaultValues: selectedDaySlots || {
      day: selectedDay || '',
      slotsRange: [],
    },
    resolver: yupResolver(overridesValidationSchema),
  });

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'slotsRange', // The name of the field array in your form data
  });

  // Created to manage custom errors other than schema errors
  const [customErrors, SetCustomErrors] = useState<
    Record<string, { message: SlotsErrorCodes }>
  >({});

  const removeCustomError = (idx: number) => {
    SetCustomErrors((prevState) => {
      const newState = { ...prevState };
      delete newState[idx];
      return newState;
    });
  };

  // --------------- DATA SETTERS & LISTENERS ---------------
  const SelectedDayBlockedSlots = useMemo(
    () => blockedSlots?.find((item) => item?.day === selectedDay),
    [selectedDay, blockedSlots],
  );

  const SelectedDayOnsiteSlots = useMemo(
    () => onsiteSlots?.find((item) => item?.day === selectedDay),
    [selectedDay, onsiteSlots],
  );

  const SelectedDayHolidaysSlots = useMemo(
    () => holidays?.find((item) => item?.day === selectedDay),
    [selectedDay, holidays],
  );

  const ClinicsMap = useMemo(() => {
    const Clinics: Record<
      string,
      { label: string; value: string; description?: string | JSX.Element }
    > = {
      virtual: { label: 'Virtual session', value: 'virtual' },
    };

    const currentDay = moment(selectedDay).day();

    clinics?.forEach((item) => {
      if (!item) return;

      const { id, name, timing } = item;
      const clinicTiming = timing?.[currentDay];
      const timingString = clinicTiming
        ? `${clinicTiming[0] ?? ''}-${clinicTiming[1] ?? ''}`
        : 'Unavailable';

      Clinics[id] = {
        label: name,
        value: id,
        description: (
          <span className="flex items-center gap-1 min-w-0">
            <ClockIcon className="w-3 h-3" />
            <span className="truncate">{timingString}</span>
          </span>
        ),
      };
    });

    return Clinics;
  }, [clinics, selectedDay]);

  useEffect(() => {
    if (workingHoursDayWiseSlots && overriddenSlots) {
      const isOverridden = overriddenSlots?.find(
        (item) => item?.day === selectedDay,
      );

      if (isOverridden) {
        setSelectedDaySlots(isOverridden);
      } else {
        const WorkingHours = workingHoursDayWiseSlots?.find(
          (item) => item?.day === selectedDay,
        );
        setSelectedDaySlots(WorkingHours);
      }
    }
  }, [workingHoursDayWiseSlots, overriddenSlots, selectedDay]);

  useEffect(() => {
    reset(selectedDaySlots);
  }, [selectedDaySlots]);

  useEffect(() => {
    setHasUnsavedChanges(isDirty);
  }, [isDirty]);

  const WatchedFields = watch('slotsRange');

  // --------------- HANDLERS ---------------
  const handleSlotUpdate = (
    newSlot: string,
    idx: number,
    source: 'from' | 'to',
  ) => {
    if (!newSlot || !WatchedFields) return;

    const restSlots = [
      ...(WatchedFields.slice(0, idx) ?? []),
      ...(WatchedFields.slice(idx + 1) ?? []),
    ];

    let updatedSlots: number[];

    if (source === 'from') {
      const existingEndSlot = WatchedFields?.[idx]?.slots?.[1];
      const newStartSlot = +newSlot;

      updatedSlots =
        newStartSlot <= existingEndSlot
          ? [newStartSlot, existingEndSlot]
          : [newStartSlot];
    } else {
      const existingStartSlot = WatchedFields?.[idx]?.slots?.[0];
      const newEndSlot = +newSlot === 0 ? 47 : +newSlot - 1;

      updatedSlots = [existingStartSlot, newEndSlot];
    }

    if (updatedSlots?.length > 1) {
      const isOverlapping = validateSlotsOverlap(
        { locationId: null, slots: updatedSlots },
        restSlots,
      );

      if (isOverlapping) {
        SetCustomErrors((prev) => ({
          ...prev,
          [idx]: { message: SlotsErrorCodes.OVERLAPPING_ERROR },
        }));
      } else {
        removeCustomError(idx);
      }
    }

    update(idx, {
      ...WatchedFields?.[idx],
      slots: updatedSlots,
    });
    trigger();
  };

  const handleSlotLocationUpdate = (locationId: string, idx: number) => {
    update(idx, {
      ...fields[idx],
      locationId: locationId === 'virtual' ? null : locationId,
    });
    trigger();
  };

  const handleRemoveSlot = (idx: number) => {
    remove(idx);
    removeCustomError(idx);
    trigger();
  };

  const handleAddSlot = () => {
    append({ locationId: null, slots: [] });
    trigger();
  };

  const renderErrorMessage = (idx: number) => {
    const error = customErrors?.[idx]?.message;

    if (error === SlotsErrorCodes.OVERLAPPING_ERROR) {
      return (
        <span className="px-1 text-xs text-error-600">
          Time overlapping with other time
        </span>
      );
    }

    return null;
  };

  const renderBlockingEvents = (idx: number) => {
    const SlicedBlockedSlots = sliceOverlappingSlots(
      SelectedDayBlockedSlots?.slotsRange ?? [],
      SelectedDayOnsiteSlots?.slotsRange ?? [],
    );

    const BlockedSlot = findOverlappingSlots(
      WatchedFields?.[idx],
      SlicedBlockedSlots ?? [],
    );

    if (BlockedSlot) {
      return generateBlockingUI(BlockedSlot, BlockingSlotsTypes.ADMIN_BLOCK);
    }

    return null;
  };

  const renderBlockingLeaveEvents = (idx: number) => {
    const SlicedBlockedSlots = sliceOverlappingSlots(
      SelectedDayHolidaysSlots?.slotsRange ?? [],
      SelectedDayOnsiteSlots?.slotsRange ?? [],
    );

    const HolidaySlot = findOverlappingSlots(
      WatchedFields?.[idx],
      SlicedBlockedSlots ?? [],
    );

    if (HolidaySlot) {
      return generateBlockingUI(HolidaySlot, BlockingSlotsTypes.LEAVE);
    }

    return null;
  };

  const renderBlockingOnsiteEvents = (idx: number) => {
    const OnsiteSlot = findOverlappingSlots(
      WatchedFields?.[idx],
      SelectedDayOnsiteSlots?.slotsRange ?? [],
    );

    if (OnsiteSlot) {
      return generateBlockingUI(OnsiteSlot, BlockingSlotsTypes.ONSITE);
    }

    return null;
  };

  const handleUpdateAvailability = () => {
    const payload = transformSlotsRangeToOverridePayload(watch());

    addOverride(payload);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex flex-col gap-2 min-h-[10vh] h-[34vh] overflow-y-scroll">
        {WatchedFields?.length ? (
          WatchedFields?.map((slotRange, idx) => {
            const key = `${selectedDay}-slot-${idx}`;

            return (
              <section
                className={twMerge(
                  'box-border grid grid-cols-12 gap-6 bg-gray-25 rounded-lg p-4',
                  customErrors?.[idx]?.message ===
                    SlotsErrorCodes.OVERLAPPING_ERROR &&
                    'bg-error-50 border border-error-300',
                )}
                key={key}
              >
                <section className="col-span-10 flex flex-col gap-2">
                  <AvailabilitySlotsPicker
                    slotRange={slotRange}
                    handleSlotUpdate={handleSlotUpdate}
                    idx={idx}
                    showSlotsTillEnd
                    error={
                      customErrors?.[idx]?.message ===
                      SlotsErrorCodes.OVERLAPPING_ERROR
                    }
                  />

                  <div>
                    <Select
                      options={Object.values(ClinicsMap)}
                      selected={slotRange?.locationId ?? 'virtual'}
                      onSelect={(value) => handleSlotLocationUpdate(value, idx)}
                      placeholder="Location"
                      fullWidth
                      className="min-w-0 max-w-[100%] text-sm"
                      listBoxClassName="w-[120%]"
                      error={
                        customErrors?.[idx]?.message ===
                        SlotsErrorCodes.OVERLAPPING_ERROR
                      }
                    />
                  </div>
                  {renderBlockingOnsiteEvents(idx)}
                  {renderBlockingLeaveEvents(idx)}
                  {renderBlockingEvents(idx)}
                  {renderErrorMessage(idx)}
                </section>
                <section className="col-span-2 flex justify-end">
                  <Button
                    variant="ghost"
                    className="flex-2 w-8 h-8 p-2 focus:bg-gray-200 flex justify-center items-center"
                    onClick={() => handleRemoveSlot(idx)}
                    autoFocus={false}
                  >
                    <DeleteTrashIcon className="text-gray-500" />
                  </Button>
                </section>
              </section>
            );
          })
        ) : (
          <div className="h-full flex items-center">
            <NoDataFound
              title={
                <span className="text-md font-semibold">
                  No availability found!
                </span>
              }
              Icon={NoAvailabilityFoundIcon}
            />
          </div>
        )}
      </div>
      <hr />
      <div className="flex flex-col gap-2">
        <Button
          className="w-full"
          disabled={
            !isEmpty(errors) || !isEmpty(customErrors) || !isDirty || isLoading
          }
          onClick={handleUpdateAvailability}
        >
          Update availability
        </Button>
        <Button
          className="w-full"
          variant="secondary"
          onClick={handleAddSlot}
          disabled={isLoading}
        >
          Add new slots
        </Button>
      </div>
    </div>
  );
}

export default AvailabilityOverride;
