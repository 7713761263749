import moment, { Moment, weekdays } from 'moment';
import lodash from 'lodash';
import {
  IAddProviderOverrideReqType,
  IClinic,
  IProviderDaySlot,
  ISlotRange,
  ISlots,
} from '../../shared/types/Availability';
import { dayOfWeekNumbers } from '../../shared/constant/Common';
import { getItemFromLocalStorage } from './Storage';

export const APIDateFormat = 'YYYY/MM/DD';

export const ClientProfileDateFormat = 'DD MMM YYYY, hh:mm A';
export const SessionTimeFormat = 'hh:mm A';
export const ClientMatchedOnDateFormat = 'DD MMM, YYYY';
export const ClientHelplineRecordDateFormat = 'DD MMM YYYY';
export const ClientHelplineRecordTimeFormat = 'hh:mm A';

export const HelplineMeetingDateFormat = 'DD MMM, YYYY ddd';
export const HelplineMeetingJaDateFormat = 'MM,DD,YYYY,dddd';
export const HelplineMeetingTimeFormat = 'hh:mm a';
export const HelplineMeetingJaTimeFormat = 'HH:mm';

/** Availability Date Formats */
export const AvailabilityAPIDateFormat = 'YYYY/MM/DD';
export const AvailabilityURLDateFormat = 'YYYY-MM-DD';
export const AvailabilityUpdateLeaveDateFormat = 'ddd, MMM DD YYYY';
export const AvailabilityUpdateLeaveTimeFormat = 'hh:mm A';

export const getTodaysDate = (format: string): string =>
  moment().format(format);

export const getTimeZone = () =>
  (getItemFromLocalStorage('userTimeZone', 'string') as string) ||
  moment.tz.guess(true);

export const getTimeFromMilliSeconds = (milliSeconds: number, format: string) =>
  moment.unix(milliSeconds).format(format);

const formatNumberTo2Digits = (input: number) => `0${input}`.slice(-2);

const convertDayMapToSlotRange = (
  input: Record<string, Omit<IProviderDaySlot, 'slotsRange'>>,
) => {
  const weekDays = lodash.clone(input) as Record<string, IProviderDaySlot>;

  Object.keys(weekDays).forEach((key: string) => {
    weekDays[key].slotsRange = [];
    Object.entries(weekDays[key].slots).forEach(
      ([locationIdKey, slotsForLocationId]) => {
        if (slotsForLocationId.length) {
          weekDays[key].slots[locationIdKey] = slotsForLocationId.sort(
            (a: number, b: number) => a - b,
          );

          let current = slotsForLocationId[0];
          let last = slotsForLocationId[0];

          slotsForLocationId.forEach((cur: number, index: number) => {
            if (index === 0) {
              // skip
            } else if (last === cur - 1) {
              last = cur;
            } else {
              weekDays[key].slotsRange.push({
                locationId: locationIdKey === 'virtual' ? null : locationIdKey,
                slots: [current, last],
              });

              current = cur;
              last = cur;
            }
          });

          weekDays[key].slotsRange.push({
            locationId: locationIdKey === 'virtual' ? null : locationIdKey,
            slots: [current, last],
          });
        }
      },
    );
  });

  return weekDays;
};

/**
 * Generates 30 minutes time
 * @param slot
 * @returns {string}
 */
export const slotToTime = (slot: number) =>
  `${formatNumberTo2Digits(
    +Math.floor((+slot * 30) / 60).toFixed(0),
  )}:${formatNumberTo2Digits((+slot * 30) % 60)}`;

export const getSlotsByWeekDay = (slotDays: ISlots[], timeZone: string) => {
  const offset = moment.tz(timeZone).utcOffset();

  const weekDays = slotDays.reduce(
    (
      res: Record<string, Omit<IProviderDaySlot, 'slotsRange'>>,
      cur: ISlots,
    ) => {
      if (cur.slots) {
        cur.slots.split(',').forEach((slot: string) => {
          const time = moment(
            `${cur.dayOfWeek} ${slotToTime(+slot)}`,
            'd HH:mm',
          ).add(offset, 'minute');

          const day = time.format('d');
          const locationId = cur.locationId || 'virtual';

          if (!res[day]) {
            res[day] = {
              day,
              dayDisplay: time.format('dddd'),
              slots: { [locationId]: [], virtual: [] },
            };
          }

          if (!res[day].slots[locationId]) {
            res[day].slots[locationId] = [];
          }

          res[day].slots[locationId].push(
            +time.format('HH') * 2 +
              +Math.floor(+time.format('mm') / 30).toFixed(0),
          );
        });
      }
      return res;
    },
    {},
  );
  return convertDayMapToSlotRange(weekDays);
};

export const getSlotsByDate = (slotDays: any[], timeZone: string) => {
  const offset = moment.tz(timeZone).utcOffset();

  const weekDays = slotDays.reduce((res: any, cur: any) => {
    if (cur.slots) {
      cur.slots.split(',').forEach((slot: string) => {
        const time = moment(
          `${cur.date} ${slotToTime(+slot)}`,
          'YYYY/MM/DD HH:mm',
        ).add(offset, 'minute');

        const formattedTime = time.format('YYYY/MM/DD');
        const locationId = cur.locationId || 'virtual';

        if (!res[formattedTime]) {
          res[formattedTime] = {
            day: formattedTime,
            dayDisplay: formattedTime,
            slots: { [locationId]: [], virtual: [] },
            id: cur.id,
            variant: cur.variant,
            localDate: cur?.localDate ?? '',
            slotIds: cur?.id ? [cur.id] : [],
          };
        }

        if (!res[formattedTime].slots[locationId]) {
          res[formattedTime].slots[locationId] = [];
        }

        res[formattedTime].slots[locationId].push(
          +time.format('HH') * 2 +
            +Math.floor(+time.format('mm') / 30).toFixed(0),
        );

        if (
          res?.[formattedTime]?.slotIds &&
          cur?.id &&
          !res?.[formattedTime]?.slotIds?.includes(cur?.id)
        ) {
          res[formattedTime].slotIds.push(cur.id);
        }
      });
    } else {
      const time = moment(`${cur.date}`, 'YYYY/MM/DD').add(offset, 'minute');

      const formattedTime = time.format('YYYY/MM/DD');

      const locationId = cur.locationId || 'virtual';

      if (!res[formattedTime]) {
        res[formattedTime] = {
          day: formattedTime,
          dayDisplay: formattedTime,
          slots: { [locationId]: [], virtual: [] },
          id: cur.id,
          variant: cur.variant,
          localDate: cur?.localDate ?? '',
          slotIds: cur?.id ? [cur.id] : [],
        };
      }

      if (!res[formattedTime].slots[locationId]) {
        res[formattedTime].slots[locationId] = [];
      }

      if (
        res?.[formattedTime]?.slotIds &&
        cur?.id &&
        !res?.[formattedTime]?.slotIds?.includes(cur?.id)
      ) {
        res[formattedTime].slotIds.push(cur.id);
      }
    }
    return res;
  }, {});
  return convertDayMapToSlotRange(weekDays);
};

export const transformSlotsToWeekDay = (
  slotMap: Record<string, IProviderDaySlot>,
  clinicsList: Partial<IClinic[]> = [],
  toUtcSlots = true,
) => {
  const offset = moment.tz(getTimeZone()).utcOffset();

  const momentArray: { [locationId: string]: Moment[] } = { virtual: [] };

  Object.values(slotMap).forEach((providerSlots) => {
    providerSlots.slotsRange.forEach((slotRange) => {
      const locationId = slotRange.locationId || 'virtual';
      if (!momentArray[locationId]) {
        momentArray[locationId] = [];
      }

      const mergedSlots = lodash.range(
        slotRange.slots[0],
        +slotRange.slots[1] + 1,
      );

      mergedSlots.forEach((mergedSlot) => {
        if (toUtcSlots) {
          momentArray[locationId].push(
            moment(
              `${providerSlots.day} ${slotToTime(+mergedSlot)}`,
              'd HH:mm',
            ).subtract(offset, 'minutes'),
          );
        } else {
          momentArray[locationId].push(
            moment(
              `${providerSlots.day} ${slotToTime(+mergedSlot)}`,
              'd HH:mm',
            ),
          );
        }
      });
    });
  });

  const dMap: Record<string, { [locationId: string]: Set<number> }> =
    Object.fromEntries(
      dayOfWeekNumbers.map((day) => [
        day,
        {
          ...Object.fromEntries(
            clinicsList.map((clinic) => [clinic?.id, new Set<number>()]),
          ),
          virtual: new Set<number>(),
        },
      ]),
    );

  Object.entries(momentArray).forEach(([locId, times]) => {
    times.forEach((time) => {
      if (!dMap[+time.format('d')][locId]) {
        dMap[+time.format('d')][locId] = new Set();
      }

      dMap[+time.format('d')][locId].add(
        +time.format('HH') * 2 +
          +Math.floor(+time.format('mm') / 30).toFixed(0),
      );
    });
  });

  const body: ISlots[] = Object.entries(dMap).flatMap(([day, slotsOfDay]) =>
    Object.entries(slotsOfDay).map(([locId, slotsAtLoc]) => {
      const slots: number[] = [];
      slotsAtLoc.forEach((slot) => slots.push(slot));
      return {
        dayOfWeek: +day,
        locationId: locId === 'virtual' ? null : locId,
        slots: slots.sort((a: number, b: number) => a - b).join(','),
      };
    }),
  );

  return body;
};

export const transformWeeklyWorkingHoursByDate = (
  workingHoursSlots: Record<string, IProviderDaySlot>,
  weeklyDates: string[],
) =>
  weeklyDates.reduce((result: IProviderDaySlot[], date) => {
    const slotsData = weekdays().reduce(
      (slotsResult: IProviderDaySlot[], weekday, index) => {
        const momentDate = moment(date).day(weekday).startOf('day');

        if (workingHoursSlots?.[index]) {
          const data = {
            ...workingHoursSlots?.[index],
            day: momentDate.format('YYYY/MM/DD').toString(),
          };

          slotsResult.push(data);
        }

        return slotsResult;
      },
      [],
    );

    result.push(...slotsData);
    return result;
  }, []);

export const findOverlappingSlots = (
  workingHoursSlots: ISlotRange,
  blockedSlots: ISlotRange[],
) => {
  const [newStartSlot, newEndSlot] = workingHoursSlots.slots;

  /**
   * Finds slots which are of single day only
   * for eg: [5,30], startSlot is always smaller and endSlot is bigger
   *
   *
   * Existing slot: [5,30]
   * Validation Cases
   * 1. new slot is between existing slot range eg. [6,9]
   * 2. new slot is ending within existing slot eg. [1,8]
   * 3. new slot is starting from existing slot eg. [25,47]
   */
  return blockedSlots?.filter((existingSlot) => {
    const [existingStartSlot, existingEndSlot] = existingSlot.slots;

    return (
      (newStartSlot >= existingStartSlot && newStartSlot <= existingEndSlot) ||
      (newEndSlot >= existingStartSlot && newEndSlot <= existingEndSlot) ||
      (newStartSlot <= existingStartSlot && newEndSlot >= existingEndSlot)
    );
  });
};

/**
 * Checks for overlapping slots
 * @param newSlot
 * @param existingSlots
 * @returns {boolean}
 */
export const validateSlotsOverlap = (
  newSlot: ISlotRange,
  existingSlots: ISlotRange[],
): boolean => {
  const [newStartSlot, newEndSlot] = newSlot.slots;

  /**
   * Validates slots which are of single day only
   * for eg: [5,30], startSlot is always smaller and endSlot is bigger
   *
   *
   * Existing slot: [5,30]
   * Validation Cases
   * 1. new slot is between existing slot range eg. [6,9]
   * 2. new slot is ending within existing slot eg. [1,8]
   * 3. new slot is starting from existing slot eg. [25,47]
   */
  return existingSlots?.some((existingSlot) => {
    const [existingStartSlot, existingEndSlot] = existingSlot.slots;

    return (
      (newStartSlot >= existingStartSlot && newStartSlot <= existingEndSlot) ||
      (newEndSlot >= existingStartSlot && newEndSlot <= existingEndSlot) ||
      (newStartSlot <= existingStartSlot && newEndSlot >= existingEndSlot)
    );
  });
};

/*

Cases of slicing

Case 1: [Working] Ending Inside
      Existing: [3,20]
      Blocking: [1,17]
      Answer: [17,20]

Case 2: [Working] Covering whole block
      Existing: [3,20]
      Blocking: [1,100]
      Answer: []

Case 3: [Working] Starting from inside
      Existing: [3,20]
      Blocking: [10,100]
      Answer: [3,10]

Case 3: [Working] Partitions
      Existing: [3,47]
      Blocking: [9,17] & [25,29]
      Answer: [3,9], [17, 25], [29,47]
*/
export const sliceOverlappingSlots = (
  working: ISlotRange[],
  blocked: ISlotRange[],
) => {
  const result: ISlotRange[] = [];

  working.forEach((workingSlot) => {
    const [currentStart, currentEnd] = workingSlot.slots;

    const slicedSlots = [];
    const overLapping = findOverlappingSlots(workingSlot, blocked);

    let prevStart = currentStart;

    overLapping.forEach((block) => {
      const blockStart = block.slots[0];
      const blockEnd = block.slots[1];

      if (prevStart < blockStart) {
        const slot = {
          locationId: workingSlot.locationId,
          slots: [prevStart, blockStart - 1],
        };

        slicedSlots.push(slot);
      }

      prevStart = Math.max(prevStart, blockEnd + 1);
    });

    if (prevStart <= currentEnd) {
      const slot = {
        locationId: workingSlot.locationId,
        slots: [prevStart, currentEnd],
      };

      slicedSlots.push(slot);
    }
    result.push(...slicedSlots);
  });

  return result;
};

export const convertTo12HourFormat = (
  time: string,
  format: string = 'h:mm A',
) => moment(time, 'HH:mm').format(format);

export interface ISlotTime {
  slot: number | '';
  time: string;
}

export const generate30MinTimeIntervals = ({
  offset = 0,
  isEnd,
  is12HrFormat = true,
  totalSlot = 48,
}: {
  offset?: number;
  isEnd?: boolean;
  is12HrFormat?: boolean;
  totalSlot?: number;
}): ISlotTime[] => {
  // TODO make it generic
  const format = is12HrFormat ? 'hh:mm A' : 'HH:mm';
  const timeIntervals: ISlotTime[] = lodash
    .range(offset, totalSlot)
    .map((slot) => ({
      slot,
      time: convertTo12HourFormat(slotToTime(+slot + (isEnd ? 1 : 0)), format),
    }));

  // for (let hour = 0; hour < 24; hour += 1) {
  //   timeIntervals.push(moment({ hour }).format('h:mm A'));
  //   timeIntervals.push(
  //     moment({
  //       hour,
  //       minute: 30,
  //     }).format('h:mm A'),
  //   );
  // }

  return timeIntervals;
};

export const transformSlotsRangeToOverridePayload = (data?: {
  day: string;
  slotsRange: ISlotRange[];
}) => {
  if (!data?.day) return [];

  // If slotsrange is empty then full day empty override
  if (!data?.slotsRange?.length) {
    return [
      {
        localDate: data?.day,
        slots: '',
        locationId: null,
        fullDay: true,
      },
    ];
  }

  const localDate = data?.day;

  const slotsResult: Record<string, IAddProviderOverrideReqType> = {};

  data?.slotsRange?.forEach((slotRange) => {
    const [start, end] = slotRange?.slots ?? {};

    const locationId = slotRange?.locationId ?? null;

    const key = `${localDate}:${locationId}`;

    // when ending slot is -1 that means slot is selected till 12:00 AM (0 slot)
    // so in that case assign 47 to it as 11:30 PM slot, which in UI shown till 12:00 AM (47 -> 0)
    const endSlot = end === -1 ? 47 : end;

    const newSlotRange = lodash.range(start, endSlot + 1);

    if (!slotsResult[key]) {
      const obj = {
        localDate,
        locationId,
        slots: newSlotRange?.join(','),
      };
      slotsResult[key] = obj;
    } else {
      const existingSlot = slotsResult[key];

      const slots = existingSlot?.slots?.split(',')?.map((slot) => +slot);

      const combinedSlots = lodash
        .uniq([...slots, ...newSlotRange])
        .sort((a: number, b: number) => a - b);
      slotsResult[key] = { ...existingSlot, slots: combinedSlots?.join(',') };
    }
  });

  return Object.values(slotsResult);
};
