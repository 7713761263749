import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ISlotRange } from '../../../shared/types/Availability';
import {
  generate30MinTimeIntervals,
  ISlotTime,
} from '../../../utilities/common/Date';
import { Select } from '../../components/Select';

export interface ITimeRange {
  startTime: string;
  endTime: string;
}

interface IAvailabilitySlotsPickerProps {
  slotRange?: ISlotRange;
  handleSlotUpdate: (
    newSlot: string,
    idx: number,
    source: 'from' | 'to',
  ) => void;
  showSlotsTillEnd?: boolean;
  idx: number;
  error?: boolean;
}

function AvailabilitySlotsPicker({
  slotRange,
  handleSlotUpdate,
  idx,
  showSlotsTillEnd = false,
  error = false,
}: IAvailabilitySlotsPickerProps) {
  const TimeIntervals: ISlotTime[] = useMemo(
    () => [...generate30MinTimeIntervals({ is12HrFormat: true })],
    [],
  );

  const [startTimeIndex, setStartTimeIndex] = useState<number>(0);

  // sub-range of end time values, we don't want duplicate keys for empty option so we conditionally builds endTimeOptions array
  const endTimeOptions: ISlotTime[] = useMemo(
    () => [
      ...TimeIntervals.slice(startTimeIndex),
      ...(showSlotsTillEnd ? TimeIntervals.slice(0, 1) : []),
    ],
    [TimeIntervals, startTimeIndex, showSlotsTillEnd],
  );

  const [startSlot, endSlot] = slotRange?.slots ?? [];

  let endingSlot = !isNil(endSlot) ? (endSlot + 1)?.toString() : '';

  endingSlot = endingSlot === '48' ? '0' : endingSlot;

  useEffect(() => {
    if (!isNil(startSlot)) {
      const selectedTimeIndex = TimeIntervals.findIndex(
        (interval) => interval.slot === startSlot,
      );

      setStartTimeIndex(selectedTimeIndex + 1);
    }
  }, [startSlot]);

  return (
    <div className="flex gap-2 items-center">
      <div className="flex-1 flex-shrink">
        <Select
          options={TimeIntervals?.slice(
            0,
            TimeIntervals.length - (showSlotsTillEnd ? 0 : 1),
          )?.map(({ slot, time }) => ({
            value: slot.toString(),
            label: time,
          }))}
          selected={startSlot?.toString()}
          onSelect={(newValue) => handleSlotUpdate(newValue, idx, 'from')}
          placeholder="From"
          fullWidth
          className="min-w-2 max-w-[100%] text-sm"
          listBoxClassName="max-h-40"
          error={error}
        />
      </div>
      <span className="flex-none">-</span>
      <div className="flex-1 flex-shrink">
        <Select
          options={endTimeOptions.map(({ slot, time }) => ({
            value: slot.toString(),
            label: time,
          }))}
          selected={endingSlot}
          onSelect={(newValue) => handleSlotUpdate(newValue, idx, 'to')}
          placeholder="To"
          fullWidth
          className="min-w-2 max-w-[100%] text-sm"
          listBoxClassName="max-h-40"
          disabled={isNil(startSlot)}
          error={error}
        />
      </div>
    </div>
  );
}
export default AvailabilitySlotsPicker;
