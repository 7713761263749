import lodash from 'lodash';
import { t } from 'i18next';
import { tagColors, tagContents } from '../../shared/constant/Helpline';
import { ColorSet } from '../../shared/types/App';
import { IProviderAttribute } from '../../shared/types/response/provider';
import { ProviderRole, SessionType } from '../../shared/constant/Common';

export const getProviderRole = (roleNum: string | undefined): string => {
  switch (roleNum) {
    case '0':
      return 'coaching';
    case '1':
      return 'clinical';
    case '5':
      return 'nutritionist';
    case '6':
      return 'fitness';
    case '7':
      return 'financial';
    case '8':
      return 'executive';
    case '9':
      return 'legal';
    case '10':
      return 'in-person';
    case '12':
      return 'onsite';
    default:
      return '';
  }
};

export const holisticRoles = ['5', '6', '7', '8', '9'];

export const getProviderRolesOptions = () => [
  {
    value: 'Coaching',
    label: t('coachingType'),
  },
  {
    value: 'Clinical',
    label: t('clinical'),
  },
  {
    value: 'Nutritionist',
    label: t('nutritionist'),
  },
  {
    value: 'Fitness',
    label: t('fitness'),
  },
  {
    value: 'Financial',
    label: t('financial'),
  },
  {
    value: 'Executive',
    label: t('executive'),
  },
  {
    value: 'Legal',
    label: t('legal'),
  },
];

export const getClientStatus = (status: string) => {
  const clientStatusColors: Record<
    string,
    { label: string; textColor: string; backgroundColor: string }
  > = {
    '0': {
      label: 'inactive',
      textColor: '#E8324A',
      backgroundColor: '#FDEBED',
    },
    '1': {
      label: 'active',
      textColor: '#008044',
      backgroundColor: '#CCEEDE',
    },
    '2': {
      label: 'matched',
      textColor: '#47515A',
      backgroundColor: '#DDE3E9',
    },
    '3': {
      label: 'booked',
      textColor: '#2A68B6',
      backgroundColor: '#D7E8FC',
    },
    '4': {
      label: 'UNMATCHED_CLIENTS',
      textColor: '#AE2638',
      backgroundColor: '#FAD6DB',
    },
  };

  return clientStatusColors[status];
};

export const isSessionCompleted = (status: string): boolean => {
  const isSessionOver = false;
  if (status === '2') return true;
  return isSessionOver;
};

export const groupProviderAttributes = (attributes: IProviderAttribute[]) =>
  lodash
    .chain(attributes)
    .groupBy('type')
    .map((v, k) => ({ type: k, value: v }))
    .value();

export const progressTypes = {
  assigned: {
    trackStatus: 0,
    cta: 'remindCTA',
    color: '#E8324A',
    label: 'notStarted',
  },
  started: {
    trackStatus: 1,
    cta: 'remindCTA',
    color: '#54756E',
    label: 'inProgress',
  },
  completed: {
    trackStatus: 2,
    cta: null,
    color: '#2A68B6',
    label: 'completed',
  },
};

export const getAssignmentProgressData = (
  progress: keyof typeof progressTypes,
) => progressTypes[progress];

export const getInternalAssignmentReferenceType = (type: string) => {
  switch (type) {
    case 'course':
      return 'course';

    case 'questionnaire':
      return 'questionnaire';

    default:
      return 'session';
  }
};

export const convertClientTagsToRender = (
  tagString: string | null | undefined,
) => {
  const convertedItems: {
    isTag: boolean;
    label: string;
    colors?: ColorSet;
  }[] = [];
  const contents = (tagString || '').split(', ');
  contents.forEach((content) => {
    if (content) {
      if (tagContents.includes(content)) {
        convertedItems.push({
          isTag: true,
          label: content,
          colors: tagColors[content as any],
        });
      } else if (tagString?.includes('Minor' || 'Assisted')) {
        // const stringWithComma = `, ${tagString}`;
        convertedItems.push({
          isTag: true,
          label: content,
          colors: tagColors[content as any],
        });
      } else {
        convertedItems.push({
          isTag: false,
          label: content,
        });
      }
    }
  });

  return convertedItems;
};

export const getSessionTypeOptionsV1 = (providerServices: string[]) => {
  const options = [];
  const servicesIncluded = Object.values(ProviderRole)
    .filter((role) => role !== ProviderRole.CAREOFFSITE)
    .some((element) => providerServices.includes(element));
  if (servicesIncluded) {
    options.push({ label: t('VIRTUAL'), value: SessionType.VIRTUAL });
  }

  if (providerServices.includes(ProviderRole.CAREOFFSITE)) {
    options.push({ label: t('INPERSON'), value: SessionType.INPERSON });
  }

  return options;
};

export const getCalendarIndicators = (providerServices: string[]) => {
  const indicators: { label: string; style: React.CSSProperties }[] = [
    {
      label: t('today'),
      style: { backgroundColor: '#1c4679' },
    },
  ];

  const isVirtual =
    providerServices.filter(
      (val) =>
        ![
          ProviderRole.CAREOFFSITE,
          ProviderRole.ONSITE,
          ProviderRole.HELPLINE,
          ProviderRole.CARENAVIGATOR,
          ProviderRole.LEGAL,
        ].includes(val as ProviderRole),
    )?.length >= 1;

  const isInPerson = providerServices.includes(ProviderRole.CAREOFFSITE);
  const isOnsite = providerServices.includes(ProviderRole.ONSITE);

  if (isVirtual) {
    indicators.push({
      label: t('VIRTUAL'),
      style: { backgroundColor: '#DBBFE5' },
    });
  }
  if (isInPerson) {
    indicators.push({
      label: t('INPERSON'),
      style: { backgroundColor: '#D1E6FA' },
    });
  }
  if (isOnsite) {
    indicators.push({
      label: t('ONSITE_LEGEND'),
      style: { backgroundColor: '#F9EF9680' },
    });
  }

  if (isInPerson && isVirtual) {
    indicators.push({
      label: `${t('INPERSON')} + ${t('VIRTUAL')}`,
      style: {
        backgroundColor: '#013A6B',
        backgroundImage:
          '-webkit-linear-gradient(135deg, #DBBFE5 50%, #D1E6FA 50%)',
      },
    });
  }
  if (isVirtual && isOnsite) {
    indicators.push({
      label: `${t('VIRTUAL')} + ${t('ONSITE_LEGEND')}`,
      style: {
        backgroundColor: 'none',
        backgroundImage:
          '-webkit-linear-gradient(135deg, #F9EF9680 50%, #DBBFE5 50%)',
      },
    });
  }
  if (isInPerson && isOnsite) {
    indicators.push({
      label: `${t('INPERSON')} + ${t('ONSITE_LEGEND')}`,
      style: {
        backgroundColor: 'none',
        backgroundImage:
          '-webkit-linear-gradient(135deg, #F9EF9680 50%, #D1E6FA 50%)',
      },
    });
  }

  if (isVirtual && isInPerson && isOnsite) {
    indicators.push({
      label: `${t('VIRTUAL')} + ${t('ONSITE_LEGEND')} + ${t('INPERSON')}`,
      style: {
        backgroundColor: 'none',
        backgroundImage:
          '-webkit-linear-gradient(135deg, #D1E6FA 0, #D1E6FA 35%, #F9EF9680 35%, #F9EF9680 65%, #DBBFE5 65%, #DBBFE5 100%)',
      },
    });
  }

  return [
    ...indicators,
    {
      label: t('ON_LEAVE'),
      style: { backgroundColor: '#FFD9CF' },
    },
  ];
};
