export enum AvailabilityEventType {
  MEETING = 'meeting',
  WORKING_HOURS_SLOT = 'working-hours-slot',
  OVERRIDDEN_SLOT = 'overridden-slot', // TODO remove this later
  ADMIN_BLOCK_SLOT = 'admin-block-slot',
  LEAVE_SLOT = 'leave-slot',
}

export type AvailabilityTabType = 'override' | 'out-of-office';

export interface IAvailabilityEventItem {
  start: Date;
  end: Date;
  title?: string;
  type: AvailabilityEventType;

  location?: string | null;
  slotInfo?: {
    slot?: IProviderDaySlot | IProviderTransformedOverrides;
    category?: SlotCategories;
  };
  meeting?: {
    id: string;
    category: MeetingCategories;
    participantName: string;
    status: string;
  };
}

export enum MeetingCategories {
  VIRTUAL = 'virtual',
  ONSITE = 'onsite',
  IN_PERSON = 'in-person',
}

export enum SlotCategories {
  VIRTUAL = 'virtual',
  ONSITE = 'onsite',
  IN_PERSON = 'in-person',
}

export enum SlotsErrorCodes {
  OVERLAPPING_ERROR = 'overlapping-error',
}

export enum BlockingSlotsTypes {
  ADMIN_BLOCK = 'admin-block',
  LEAVE = 'leave',
  ONSITE = 'onsite',
}

export enum ProviderOverrideVariant {
  Override = '1',
  Leave = '2',
  OverrideInPerson = '3',
  OnSite = '4',
  Block = '5',
  BlockExternal = '6',
}

export interface IProviderRawAvailability {
  createdAt: string;
  updatedAt: string;
  userId: number;
  isActive: boolean;
  timezone: string;
  canMatch: boolean;
  ranking: number;
  providerCode: string;
  appVersion: string;
  slots: IProviderRawSlots[];
}

export interface IProviderRawSlots {
  createdAt: string;
  updatedAt: string;
  id: number;
  providerId: number;
  dayOfWeek: number;
  slots: string;
  locationId: string | null;
}

export interface ISlots {
  dayOfWeek: number;
  slots: string;
  locationId: string | null;
}

export interface ISlotRange {
  locationId: string | null;
  slots: number[];
}

export interface IProviderDaySlot {
  day: string;
  dayDisplay: string;
  slots: Record<string, number[]>;
  slotsRange: ISlotRange[];
}

export interface IProviderTransformedOverrides {
  id: number;
  day: string;
  dayDisplay: string;
  slots: Set<number>;
  slotsRange: ISlotRange[];
  variant: string;
  localDate?: string;
  slotIds?: number[];
}

export interface IProviderServicesAndClinic {
  clinics: IClinic[];
  services: string[];
}

export interface IClinic {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  isActive: boolean;
  cityId: number;
  timezone: string;
  timing: Record<string, string[]>;
}

export interface IProviderAvailabilityOverrides {
  overrides: IProviderRawOverriddenSlots[];
}

export interface IProviderRawOverriddenSlots {
  createdAt: string;
  updatedAt: string;
  id: number;
  providerId: number;
  date: string;
  localDate: string;
  slots: string;
  locationId: any;
  onsiteId: any;
  variant: string;
  externalCalendarId: any;
}

export interface IAddProviderOverrideReqType {
  localDate: string;
  locationId: string | null;
  slots: string;
}

export interface IRemoveProviderLeavesReqType {
  leaveIds: number[];
}

export interface IAddProviderLeavesReqType {
  from: number;
  to: number;
}
