import * as React from 'react';
import './Schedule.scss';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Typography from '../../components/Typography';
import ScheduleTimeSlot from '../../app-components/ScheduleTimeSlot';
import { getProviderSlotsV2 } from '../../../shared/redux/actions/provider';
import withTracking from '../../../utilities/hoc/withTracking';
import { TrackFunction } from '../../../utilities/hooks/useTracking';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';
import DailyOverrideTab from '../../app-components/DailyOverrideTab';
import { IProviderDaySlotV1 } from '../../../shared/types/response/provider';

export interface IScheduleProps {
  getProviderSlots: () => Promise<Record<string, IProviderDaySlotV1>>;
  track: TrackFunction;
  t: TFunction<'translation', undefined>;
}

export interface IScheduleState {
  selectedTab: string;
  providerSlots: Record<string, IProviderDaySlotV1>;
  leaveMarker: boolean;
}

class Schedule extends React.Component<IScheduleProps, IScheduleState> {
  //* TODO tabs logic with id
  tabs = ['WORKING_HOURS', 'MONTHLY_SCHEDULE'];

  constructor(props: IScheduleProps) {
    super(props);

    this.state = {
      selectedTab: this.tabs[0],
      providerSlots: {},
      leaveMarker: false,
    };
  }

  componentDidMount() {
    const { track } = this.props;
    this.setProviderSlots();
    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.schedule,
      eventLabel: 'schedule',
    });
  }

  setProviderSlots = () => {
    const { getProviderSlots } = this.props;
    getProviderSlots().then((providerSlots) => {
      this.setState({ providerSlots });
    });
  };

  onTabClick = (tab: string): void => {
    this.setState({
      selectedTab: tab,
    });
  };

  public render() {
    const { selectedTab, providerSlots, leaveMarker } = this.state;

    const { t } = this.props;
    return (
      <div className="schedule-container">
        <div className="heading-container">
          <Typography weight="600" color="primary" size={24}>
            {t('schedule')}
          </Typography>
          <div className="content-container">
            <div className="tab-headers">
              {this.tabs.map((tab, index) => (
                <div
                  role="button"
                  tabIndex={index}
                  className={`tab-heading ${
                    selectedTab === tab ? 'selected' : ''
                  }`}
                  onClick={() => this.onTabClick(tab)}
                  key={tab}
                >
                  <Typography color="primary" weight="600" size={14}>
                    {t(tab)}
                  </Typography>
                </div>
              ))}
            </div>
            <div className="schedule-tabs-container">
              {/* // TODO Convert this approach of tab management to compound component design pattern using contexts */}
              {selectedTab === 'WORKING_HOURS' && (
                <ScheduleTimeSlot
                  onTabChange={this.onTabClick}
                  slots={providerSlots}
                  show={selectedTab === 'WORKING_HOURS'}
                  onSlotsChange={this.setProviderSlots}
                  showLeaveMarker={leaveMarker}
                  updateLeaveMarker={(value) =>
                    this.setState({ leaveMarker: value })
                  }
                />
              )}
              {selectedTab === 'MONTHLY_SCHEDULE' && (
                <DailyOverrideTab
                  onTabChange={this.onTabClick}
                  updateLeaveMarker={(value) =>
                    this.setState({ leaveMarker: value })
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ScheduleWithTranslations = withTranslation()(Schedule);

const mapDispatchToProps = (dispatch: Function) => ({
  getProviderSlots: () => dispatch(getProviderSlotsV2()),
});

export default connect(
  null,
  mapDispatchToProps,
)(withTracking(ScheduleWithTranslations));
