import './App.css';
import { Toaster } from 'sonner';
import ErrorBoundary from './view/app-components/ErrorBoundary';
import AppRoutes from './AppRoutes';
import { ToastConfig, ToastIconConfig } from './v2/config/ToastConfig';

// TODO lazy load screens + suspense fallback

function App() {
  return (
    <ErrorBoundary>
      <Toaster
        position="top-center"
        toastOptions={ToastConfig}
        icons={ToastIconConfig}
      />
      <AppRoutes />
    </ErrorBoundary>
  );
}

export default App;
