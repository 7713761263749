import { isArray } from 'lodash';
import moment from 'moment';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { SlotInfo, View } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import { getTimeZone } from '../../../../utilities/common/Date';
import { ReactComponent as ConfusedIcon } from '../../../assets/ConfusedIcon.svg';
import { StorageItems } from '../../../shared/constant/App';
import {
  AvailabilityEventType,
  AvailabilityTabType,
  IAvailabilityEventItem,
  IClinic,
  IProviderDaySlot,
  IProviderTransformedOverrides,
  ProviderOverrideVariant,
  SlotCategories,
} from '../../../shared/types/Availability';
import {
  dateStringBuilder,
  getMeetingCategory,
  getStartEndDates,
} from '../../../utilities/common/Availability';
import {
  AvailabilityAPIDateFormat,
  AvailabilityURLDateFormat,
  getSlotsByDate,
  getSlotsByWeekDay,
  sliceOverlappingSlots,
  transformWeeklyWorkingHoursByDate,
} from '../../../utilities/common/Date';
import {
  addItemToLocalStorage,
  getItemFromLocalStorage,
} from '../../../utilities/common/Storage';
import {
  encodeQueryStrings,
  useQueryString,
} from '../../../utilities/hooks/useQueryString';
import AvailabilityAdminBlockHoursEvent from '../../app-components/AvailabilityCalendar/AvailabilityAdminBlockHoursEvent';
import AvailabilityCalendar from '../../app-components/AvailabilityCalendar/AvailabilityCalendar';
import AvailabilityEvent from '../../app-components/AvailabilityCalendar/AvailabilityEvent';
import AvailabilityHeader from '../../app-components/AvailabilityCalendar/AvailabilityHeader';
import AvailabilityLeaveHoursEvent from '../../app-components/AvailabilityCalendar/AvailabilityLeaveHoursEvent';
import AvailabilityToolbar from '../../app-components/AvailabilityCalendar/AvailabilityToolbar';
import AvailabilityWorkingHoursEvent from '../../app-components/AvailabilityCalendar/AvailabilityWorkingHoursEvent';
import AvailabilityEditSlotsPopup from '../../app-components/AvailabilitySlotsPopup/AvailabilityEditSlotsPopup';
import MeetingInfoPopup from '../../app-components/MeetingInfoPopup/MeetingInfoPopup';
import Modal from '../../components/Modal';
import NoDataFound from '../../components/NoDataFound';
import AvailabilityActionsBar from './AvailabilityActionsBar';
import AvailabilityFiltersAndActions from './AvailabilityFiltersAndActions';
import AvailabilityContext from './context/AvailabilityContext';
import {
  useProviderAvailabilities,
  useProviderOverrides,
  useProviderServicesAndClinics,
} from './hooks/UseAvailabilities';
import { useProviderMeetings } from './hooks/UseMeetings';
import { ProviderRole } from '../../../shared/constant/Common';

const ProviderTimezone = getTimeZone();

moment.tz.setDefault(ProviderTimezone);

interface VirtualReference {
  getBoundingClientRect: () => DOMRectReadOnly;
}

function EventComponents({ event }: { event: IAvailabilityEventItem }) {
  switch (event?.type) {
    case AvailabilityEventType.WORKING_HOURS_SLOT:
    case AvailabilityEventType.OVERRIDDEN_SLOT:
      return <AvailabilityWorkingHoursEvent event={event} />;
    case AvailabilityEventType.LEAVE_SLOT:
      return <AvailabilityLeaveHoursEvent />;
    case AvailabilityEventType.ADMIN_BLOCK_SLOT:
      return <AvailabilityAdminBlockHoursEvent />;
    case AvailabilityEventType.MEETING:
    default:
      return <AvailabilityEvent event={event} />;
  }
}

function Availability() {
  const history = useNavigate();
  const queryString: Record<string, any> = useQueryString({
    arrayFormat: 'separator',
    arrayFormatSeparator: ',',
  });

  // --------------- STATE MANAGEMENT ---------------
  const [meetingRefElement, setMeetingRefElement] =
    useState<HTMLElement | null>(null);
  const [availabilityRefElement, setAvailabilityRefElement] = useState<{
    element: HTMLElement | VirtualReference | null;
    selectedDay: string | null;
    tab: AvailabilityTabType;
    event: IAvailabilityEventItem | null;
  }>({ element: null, selectedDay: null, tab: 'override', event: null });

  const [dateString, setDateString] = useState<{
    fromDate: string;
    toDate: string;
  }>(
    queryString?.fromDate && queryString?.toDate
      ? { fromDate: queryString.fromDate, toDate: queryString.toDate }
      : dateStringBuilder(moment().startOf('week'), moment().endOf('week')),
  );

  const [calendarSelectedDate, setCalendarSelectedDate] = useState<Date>(
    queryString?.currentDate ? queryString?.currentDate : moment().toDate(),
  );

  const [selectedCalendarView, setSelectedCalendarView] = useState<View>(
    () =>
      (getItemFromLocalStorage(
        StorageItems.CALENDAR_DEFAULT_VIEW,
        'string',
      ) as View) || 'week',
  );

  const [selectedMeeting, setSelectedMeeting] = useState<string | undefined>(
    undefined,
  );

  const [filters, setFilters] = useState<{
    virtual: boolean;
    inPerson: string[];
    onSite: boolean;
  }>({
    virtual: true,
    inPerson: ['1'],
    onSite: true,
  });

  const [workingHoursDayWiseSlots, setWorkingHoursDayWiseSlots] = useState<
    IProviderDaySlot[]
  >([]);

  const [overriddenSlots, setOverriddenSlots] = useState<
    IProviderTransformedOverrides[]
  >([]);
  const [onsiteSlots, setOnsiteSlots] = useState<
    IProviderTransformedOverrides[]
  >([]);
  const [blockedSlots, setBlockedSlots] = useState<
    IProviderTransformedOverrides[]
  >([]);
  const [holidays, setHolidays] = useState<IProviderTransformedOverrides[]>([]);

  const [showBlockOnsiteModal, setShowBlockOnsiteModal] = useState<{
    open: boolean;
    mode: 'block' | 'onsite' | null;
  }>({ open: false, mode: null });

  // --------------- NETWORK CALLS ---------------
  const { data: providerMeetings } = useProviderMeetings(
    moment(dateString.fromDate).format(AvailabilityAPIDateFormat).toString(),
    moment(dateString.toDate).format(AvailabilityAPIDateFormat).toString(),
    ProviderTimezone,
  );
  const { data: availability } = useProviderAvailabilities();
  const { data: overrides, refetch: referchOverrides } = useProviderOverrides(
    moment(dateString.fromDate).format(AvailabilityAPIDateFormat).toString(),
    moment(dateString.toDate).format(AvailabilityAPIDateFormat).toString(),
  );
  const { data: servicesAndClinics } = useProviderServicesAndClinics();

  // --------------- DATA SETTERS & LISTENERS ---------------
  useEffect(() => {
    const params: Record<string, any> = {
      ...queryString,
      ...dateString,
      currentDate: moment(calendarSelectedDate).format(
        AvailabilityURLDateFormat,
      ),
    };

    history({ search: encodeQueryStrings(params) }, { replace: true });
  }, [dateString, calendarSelectedDate]);

  useEffect(() => {
    setDateString(
      dateStringBuilder(
        moment(calendarSelectedDate).startOf('week'),
        moment(calendarSelectedDate).endOf('week'),
      ),
    );
  }, [calendarSelectedDate]);

  useEffect(() => {
    if (!availability?.slots || !dateString?.fromDate) {
      return;
    }

    const WorkingHours = getSlotsByWeekDay(
      availability?.slots,
      ProviderTimezone,
    );

    setWorkingHoursDayWiseSlots(
      transformWeeklyWorkingHoursByDate(WorkingHours, [dateString?.fromDate]),
    );
  }, [availability, dateString?.fromDate]);

  useEffect(() => {
    if (!overrides?.overrides) {
      return;
    }
    const rawSlots = overrides?.overrides ?? [];
    const OverriddenSlots = rawSlots?.filter(
      (item: any) =>
        ![
          ProviderOverrideVariant.Leave,
          ProviderOverrideVariant.Block,
          ProviderOverrideVariant.BlockExternal,
          ProviderOverrideVariant.OnSite,
        ]?.includes(item?.variant),
    );

    const OnsiteSlots = rawSlots?.filter(
      (item) => item?.variant === ProviderOverrideVariant.OnSite,
    );

    const BlockedSlots = rawSlots?.filter(
      (item) => item?.variant === ProviderOverrideVariant.Block,
    );

    const HolidaysSlots = rawSlots?.filter(
      (item) => item?.variant === ProviderOverrideVariant.Leave,
    );

    const transformedOverriddens: IProviderTransformedOverrides[] =
      Object.values(getSlotsByDate(OverriddenSlots, ProviderTimezone)) as any[];

    const transformedOnsiteSlots: IProviderTransformedOverrides[] =
      Object.values(getSlotsByDate(OnsiteSlots, ProviderTimezone)) as any[];

    const transformedBlocked: IProviderTransformedOverrides[] = Object.values(
      getSlotsByDate(BlockedSlots, ProviderTimezone),
    ) as any[];

    const transformedHolidays: IProviderTransformedOverrides[] = Object.values(
      getSlotsByDate(HolidaysSlots, ProviderTimezone),
    ) as any[];

    setOverriddenSlots(transformedOverriddens);
    setOnsiteSlots(transformedOnsiteSlots);
    setBlockedSlots(transformedBlocked);
    setHolidays(transformedHolidays);
  }, [overrides]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      inPerson: servicesAndClinics?.clinics?.map((item) => item?.id) ?? [],
    }));
  }, [servicesAndClinics?.clinics]);

  useEffect(() => {
    addItemToLocalStorage(
      StorageItems.CALENDAR_DEFAULT_VIEW,
      selectedCalendarView,
    );
  }, [selectedCalendarView]);

  const ClinicsMap = useMemo(() => {
    const map: Record<string, string> = {};

    servicesAndClinics?.clinics?.forEach((item: IClinic) => {
      if (item && !map?.[item?.id]) {
        map[item?.id] = item?.name;
      }
    });

    return map;
  }, [servicesAndClinics?.clinics]);

  // --------------- HANDLERS ---------------
  const handleSelectSlot = (slotInfo: SlotInfo) => {
    // Disabling slot selection on past dates
    if (moment(slotInfo?.end).isBefore(moment())) {
      return;
    }

    const { bounds } = slotInfo;
    if (bounds) {
      const cords = {
        top: bounds.top - 100,
        left: bounds.left,
        right: bounds.right,
        bottom: bounds.bottom,
        width: bounds.right - bounds.left,
        height: bounds.bottom - bounds.top,
        x: bounds.x,
        y: bounds.y,
      };

      setAvailabilityRefElement({
        element: {
          getBoundingClientRect: () => ({
            ...cords,
            toJSON: () => cords,
          }),
        },
        tab: 'override',
        selectedDay: moment(slotInfo.start)
          .format(AvailabilityAPIDateFormat)
          .toString(),
        event: null,
      });
    }
  };

  const handleSelectEvent = (
    event: IAvailabilityEventItem,
    e: SyntheticEvent<HTMLElement>,
  ) => {
    e.preventDefault();

    // Disabling slot edit of past dates
    if (
      event?.type !== AvailabilityEventType.MEETING &&
      moment(event?.end).isBefore(moment())
    ) {
      return;
    }

    let popupTab: AvailabilityTabType = 'override';
    if (event?.type === AvailabilityEventType.MEETING) {
      setSelectedMeeting(event?.meeting?.id);
      setMeetingRefElement(e.currentTarget);
    } else if (event?.type === AvailabilityEventType.ADMIN_BLOCK_SLOT) {
      setShowBlockOnsiteModal({ open: true, mode: 'block' });
    } else if (
      event?.type === AvailabilityEventType.OVERRIDDEN_SLOT &&
      event?.slotInfo?.category === SlotCategories.ONSITE
    ) {
      setShowBlockOnsiteModal({ open: true, mode: 'onsite' });
    } else {
      if (event?.type === AvailabilityEventType.LEAVE_SLOT) {
        popupTab = 'out-of-office';
      }

      setAvailabilityRefElement({
        element: e.currentTarget,
        selectedDay: moment(event.start)
          .format(AvailabilityAPIDateFormat)
          .toString(),
        tab: popupTab,
        event,
      });
    }
  };

  const WorkingHoursBGEvents = useMemo(
    () =>
      workingHoursDayWiseSlots?.reduce(
        (result: IAvailabilityEventItem[], working) => {
          const momentDate = moment(
            working?.day,
            AvailabilityAPIDateFormat,
          ).startOf('day');
          // Note: removed ...holidays as in partial leaves provider is available in rest of the timings according to their slots
          const isHolidayExistOnSameDay = holidays?.find(
            (item) =>
              moment(item?.localDate, AvailabilityAPIDateFormat)
                .format(AvailabilityAPIDateFormat)
                .toString() ===
              momentDate.clone().format(AvailabilityAPIDateFormat).toString(),
          );

          const isOverriddenSlotExist = overriddenSlots?.some(
            (item) =>
              moment(item?.localDate, AvailabilityAPIDateFormat)
                .format(AvailabilityAPIDateFormat)
                .toString() ===
              momentDate.clone().format(AvailabilityAPIDateFormat).toString(),
          );

          const isOnsiteSlotExist = onsiteSlots?.find(
            (item) =>
              moment(item?.localDate, AvailabilityAPIDateFormat)
                .format(AvailabilityAPIDateFormat)
                .toString() ===
              momentDate.clone().format(AvailabilityAPIDateFormat).toString(),
          );

          // There can be only leaves or blocks on a single day
          const SameDayBlockedSlots = !isHolidayExistOnSameDay
            ? blockedSlots?.find(
                (item) =>
                  moment(item?.localDate, AvailabilityAPIDateFormat)
                    .format(AvailabilityAPIDateFormat)
                    .toString() ===
                  momentDate
                    .clone()
                    .format(AvailabilityAPIDateFormat)
                    .toString(),
              )
            : isHolidayExistOnSameDay;

          // Slicing out blocking slots from Onsite Slots, to show onsite slots on the top having highest priority
          const SlicedBlockingSlots = sliceOverlappingSlots(
            SameDayBlockedSlots?.slotsRange ?? [],
            isOnsiteSlotExist?.slotsRange ?? [],
          );

          // Slicing out woking hours from the result of above slicing
          const SlicedSlotsRangeWithBlocking = sliceOverlappingSlots(
            working?.slotsRange ?? [],
            SlicedBlockingSlots ?? [],
          );

          // Slicing out above result of working hours with onsite slots
          const SlicedSlotsRange = sliceOverlappingSlots(
            SlicedSlotsRangeWithBlocking ?? [],
            isOnsiteSlotExist?.slotsRange ?? [],
          );

          if (SlicedSlotsRange?.length && !isOverriddenSlotExist) {
            const data: {
              start: Date;
              end: Date;
              title: string;
              type: AvailabilityEventType;
              location: string | null;
              slotInfo: { slot: IProviderDaySlot; category: SlotCategories };
            }[] = [];

            [...(SlicedSlotsRange ?? [])]
              .sort((a, b) => (a?.slots?.[0] > b?.slots?.[0] ? 1 : -1))
              .forEach((slot) => {
                const { start, end } = getStartEndDates(momentDate, slot);

                const obj = {
                  start,
                  end,
                  title: 'Available @ Virtual',
                  type: AvailabilityEventType.WORKING_HOURS_SLOT,
                  slotInfo: {
                    slot: working,
                    category: slot?.locationId
                      ? SlotCategories.IN_PERSON
                      : SlotCategories.VIRTUAL,
                  },
                  location: slot?.locationId ?? null,
                };
                data.push(obj);
              });

            result.push(...data);
          }
          return result;
        },
        [],
      ) ?? [],
    [
      workingHoursDayWiseSlots,
      dateString,
      overriddenSlots,
      holidays,
      blockedSlots,
    ],
  );

  const OverriddenBGEvents = useMemo(
    () =>
      [...overriddenSlots]?.reduce(
        (result: IAvailabilityEventItem[], override) => {
          const momentDate = moment(
            override?.day,
            AvailabilityAPIDateFormat,
          ).startOf('day');
          const isHolidayExistOnSameDay = holidays?.some(
            (item) =>
              moment(item?.localDate, AvailabilityAPIDateFormat)
                .format(AvailabilityAPIDateFormat)
                .toString() ===
              momentDate.clone().format(AvailabilityAPIDateFormat).toString(),
          );

          const isOnsiteSlotExist = onsiteSlots?.find(
            (item) =>
              moment(item?.localDate, AvailabilityAPIDateFormat)
                .format(AvailabilityAPIDateFormat)
                .toString() ===
              momentDate.clone().format(AvailabilityAPIDateFormat).toString(),
          );

          const SameDayBlockedSlots = blockedSlots?.find(
            (item) =>
              moment(item?.localDate, AvailabilityAPIDateFormat)
                .format(AvailabilityAPIDateFormat)
                .toString() ===
              momentDate.clone().format(AvailabilityAPIDateFormat).toString(),
          );

          // Slicing out blocking slots from Onsite Slots, to show onsite slots on the top having highest priority
          const SlicedBlockingSlots = sliceOverlappingSlots(
            SameDayBlockedSlots?.slotsRange ?? [],
            isOnsiteSlotExist?.slotsRange ?? [],
          );

          // If same day slots exist then do the slicing operation
          const SlicedSlotsRangeWithBlocking = SameDayBlockedSlots?.slotsRange
            ?.length
            ? sliceOverlappingSlots(
                override?.slotsRange ?? [],
                SlicedBlockingSlots ?? [],
              )
            : override?.slotsRange ?? [];

          // Slicing out above result of overrides with onsite slots
          const SlicedSlotsRange = sliceOverlappingSlots(
            SlicedSlotsRangeWithBlocking ?? [],
            isOnsiteSlotExist?.slotsRange ?? [],
          );

          if (
            SlicedSlotsRange?.length &&
            !(
              isHolidayExistOnSameDay &&
              (
                [
                  ProviderOverrideVariant.Override,
                  ProviderOverrideVariant.OverrideInPerson,
                  ProviderOverrideVariant.OnSite,
                ] as string[]
              ).includes(override.variant)
            )
          ) {
            const data: {
              start: Date;
              end: Date;
              title: string;
              type: AvailabilityEventType;
              location: string | null;
              slotInfo: {
                slot: IProviderTransformedOverrides;
                category: SlotCategories;
              };
            }[] = [];

            [...(SlicedSlotsRange ?? [])]
              .sort((a, b) => (a?.slots?.[0] > b?.slots?.[0] ? 1 : -1))
              .forEach((slot) => {
                const location = slot?.locationId ?? null;

                let slotCategory = SlotCategories.VIRTUAL;

                if (slot?.locationId) {
                  slotCategory = SlotCategories.IN_PERSON;
                }

                const { start, end } = getStartEndDates(momentDate, slot);

                const obj = {
                  start,
                  end,
                  title: `Available${
                    location && ClinicsMap?.[location]
                      ? ` @ ${ClinicsMap[location]}`
                      : ''
                  }`,
                  type: AvailabilityEventType.OVERRIDDEN_SLOT,
                  location,
                  slotInfo: {
                    slot: override,
                    category: slotCategory,
                  },
                };

                data.push(obj);
              });

            result.push(...data);
          }

          return result;
        },
        [],
      ) ?? [],
    [overriddenSlots, blockedSlots, holidays, dateString, ClinicsMap],
  );

  const AdminBlocksAndHolidaysBGEvents = useMemo(
    () =>
      [...holidays, ...blockedSlots]?.reduce(
        (result: IAvailabilityEventItem[], hol) => {
          const momentDate = moment(
            hol.localDate,
            AvailabilityAPIDateFormat,
          ).startOf('day');

          const isOnsiteSlotExist = onsiteSlots?.find(
            (item) =>
              moment(item?.localDate, AvailabilityAPIDateFormat)
                .format(AvailabilityAPIDateFormat)
                .toString() ===
              momentDate.clone().format(AvailabilityAPIDateFormat).toString(),
          );

          // Slicing out holidays/admin-blocks from onsite block
          const SlicedSlotsRange = sliceOverlappingSlots(
            hol?.slotsRange ?? [],
            isOnsiteSlotExist?.slotsRange ?? [],
          );

          if (SlicedSlotsRange?.length) {
            const data = [...(SlicedSlotsRange ?? [])]
              .sort((a, b) => (a?.slots?.[0] > b?.slots?.[0] ? 1 : -1))
              .map((slot) => {
                const { start, end } = getStartEndDates(momentDate, slot);

                const info = {
                  title: 'Holiday',
                  type: AvailabilityEventType.LEAVE_SLOT,
                  category: slot?.locationId
                    ? SlotCategories.IN_PERSON
                    : SlotCategories.VIRTUAL,
                };

                if (hol?.variant === ProviderOverrideVariant.Block) {
                  info.title = 'Admin Block';
                  info.type = AvailabilityEventType.ADMIN_BLOCK_SLOT;
                }

                if (hol?.variant === ProviderOverrideVariant.OnSite) {
                  info.title = 'Onsite Block';
                  info.type = AvailabilityEventType.OVERRIDDEN_SLOT;
                  info.category = SlotCategories.ONSITE;
                }

                return {
                  start,
                  end,
                  title: info.title,
                  type: info.type,
                  slotInfo: {
                    slot: hol,
                    category: info.category,
                  },
                  location: slot?.locationId ?? null,
                };
              });

            result.push(...data);
          }

          return result;
        },
        [],
      ) ?? [],
    [holidays, blockedSlots, onsiteSlots],
  );

  const OnsiteBGEvents = useMemo(
    () =>
      onsiteSlots?.reduce((result: IAvailabilityEventItem[], hol) => {
        if (hol?.slotsRange?.length) {
          const data = [...(hol?.slotsRange ?? [])]
            .sort((a, b) => (a?.slots?.[0] > b?.slots?.[0] ? 1 : -1))
            .map((slot) => {
              const momentDate = moment(
                hol.localDate,
                AvailabilityAPIDateFormat,
              ).startOf('day');
              const { start, end } = getStartEndDates(momentDate, slot);

              return {
                start,
                end,
                title: 'Onsite Block',
                type: AvailabilityEventType.OVERRIDDEN_SLOT,
                slotInfo: {
                  slot: hol,
                  category: SlotCategories.ONSITE,
                },
                location: slot?.locationId ?? null,
              };
            });

          result.push(...data);
        }

        return result;
      }, []) ?? [],
    [onsiteSlots],
  );

  const ProviderMeetings: IAvailabilityEventItem[] = useMemo(
    () =>
      providerMeetings
        ?.filter((meeting) => {
          if (!meeting) return false; // Guard clause for undefined or null meeting

          const { providerRole } = meeting;

          if (
            filters.virtual &&
            (
              [
                ProviderRole.COACHING,
                ProviderRole.CLINICAL,
                ProviderRole.NUTRITIONIST,
                ProviderRole.FITNESS,
                ProviderRole.FINANCIAL,
                ProviderRole.EXECUTIVE,
                ProviderRole.LEGAL,
              ] as string[]
            ) // Coaching, Clinical, Holistic roles
              .includes(providerRole)
          ) {
            return true;
          }

          if (filters.onSite && providerRole === ProviderRole.ONSITE) {
            return true;
          }

          if (
            filters.inPerson?.length &&
            providerRole === ProviderRole.CAREOFFSITE &&
            filters.inPerson?.includes(meeting?.location?.locationId)
          ) {
            return true;
          }

          return false;
        })
        ?.map((meeting) => ({
          start: moment.unix(+meeting.scheduledStartTime).toDate(),
          end: moment.unix(+meeting.scheduledEndTime).toDate(),
          title: `Meeting with ${meeting.friendlyName}`,
          type: AvailabilityEventType.MEETING,
          meeting: {
            id: meeting.id,
            category: getMeetingCategory(meeting.providerRole),
            participantName: meeting.friendlyName,
            status: meeting.status,
          },
        })) ?? [],
    [providerMeetings, filters],
  );

  const AvailabilityContextValues = useMemo(
    () => ({
      overriddenSlots,
      workingHoursDayWiseSlots,
      blockedSlots,
      holidays,
      onsiteSlots,
      clinics: servicesAndClinics?.clinics ?? [],
      services: servicesAndClinics?.services ?? [],
      referchOverrides,
    }),
    [
      overriddenSlots,
      workingHoursDayWiseSlots,
      blockedSlots,
      holidays,
      onsiteSlots,
      servicesAndClinics,
    ],
  );

  return (
    <AvailabilityContext.Provider value={AvailabilityContextValues}>
      <article
        className="flex flex-col m-9 gap-[1rem]"
        style={{ width: 'calc(100% - 72px)', fontFamily: 'Inter, sans-serif' }} // TODO temporary
      >
        <section className="flex flex-col gap-4">
          <AvailabilityActionsBar
            selectedCalendarView={selectedCalendarView}
            setCalendarSelectedDate={setCalendarSelectedDate}
            setSelectedCalendarView={setSelectedCalendarView}
          />

          <section className="flex items-start justify-start gap-4">
            <AvailabilityFiltersAndActions
              calendarSelectedDate={calendarSelectedDate}
              setCalendarSelectedDate={setCalendarSelectedDate}
              filters={filters}
              setFilters={setFilters}
            />
            <section className="flex-7 w-full">
              <AvailabilityCalendar
                date={calendarSelectedDate}
                onNavigate={() => {}} // Doing nothing as we have built custom date changer
                defaultDate={dateString?.fromDate || moment().toDate()}
                onSelectSlot={handleSelectSlot}
                onRangeChange={(obj) => {
                  if (isArray(obj)) {
                    setDateString(
                      dateStringBuilder(
                        moment(obj[0]),
                        moment(obj[obj.length - 1]).endOf('day'),
                      ),
                    );
                  } else {
                    setDateString(
                      dateStringBuilder(moment(obj.start), moment(obj.end)),
                    );
                  }
                }}
                selectable
                backgroundEvents={[
                  ...WorkingHoursBGEvents,
                  ...OverriddenBGEvents,
                  ...AdminBlocksAndHolidaysBGEvents,
                  ...OnsiteBGEvents,
                ]}
                events={ProviderMeetings}
                view={selectedCalendarView}
                onView={() => {}} // Doing nothing as we have built custom view changer
                views={['day', 'week']}
                components={{
                  toolbar: AvailabilityToolbar,
                  header: AvailabilityHeader,
                  event: EventComponents,
                }}
                startAccessor="start"
                endAccessor="end"
                onSelectEvent={handleSelectEvent}
                formats={{ timeGutterFormat: 'HH:mm', dayFormat: 'ddd DD' }}
                scrollToTime={moment().toDate()}
              />
            </section>
          </section>
        </section>
        <MeetingInfoPopup
          anchorEl={meetingRefElement}
          handleClose={() => setMeetingRefElement(null)}
          meetingId={selectedMeeting}
        />
        <AvailabilityEditSlotsPopup
          anchorEl={availabilityRefElement.element}
          tab={availabilityRefElement.tab}
          handleClose={() =>
            setAvailabilityRefElement({
              element: null,
              selectedDay: null,
              tab: 'override',
              event: null,
            })
          }
          selectedDay={availabilityRefElement.selectedDay}
          event={availabilityRefElement.event}
        />
        <Modal
          open={showBlockOnsiteModal.open}
          handleClose={() =>
            setShowBlockOnsiteModal((prev) => ({ ...prev, open: false }))
          }
          className="w-[36vw]"
        >
          <NoDataFound
            title={
              <span className="text-md font-semibold">
                {showBlockOnsiteModal.mode === 'block'
                  ? 'Admin block'
                  : 'Onsite block'}
              </span>
            }
            description="This block has been configured by the administrator. Reach out to us at providers@intellect.co for more information."
            Icon={ConfusedIcon}
          />
        </Modal>
      </article>
    </AvailabilityContext.Provider>
  );
}

export default Availability;
