import { toast } from 'sonner';
import { useMutation } from 'react-query';
import {
  IClinic,
  IProviderDaySlot,
} from '../../../../shared/types/Availability';
import { transformSlotsToWeekDay } from '../../../../utilities/common/Date';
import { saveWorkingHoursSlots } from '../../../../shared/service/API/provider';

export const useUpdateWorkingHours = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) =>
  useMutation({
    mutationFn: ({
      slotMap,
      clinicsList,
    }: {
      slotMap: Record<string, IProviderDaySlot>;
      clinicsList: IClinic[];
    }) => {
      const body = transformSlotsToWeekDay(slotMap, clinicsList);
      return saveWorkingHoursSlots(body);
    },
    onError(data: { error: { message: string } }) {
      toast.success(data.error.message);
    },
    onSuccess: () => {
      toast.success('Working hours updated');
      onSuccess();
    },
  });
