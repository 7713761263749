import { useMutation } from 'react-query';
import { toast } from 'sonner';
import { saveSlots } from '../../../shared/redux/actions/provider';
import {
  IProviderDaySlotV1,
  InPersonClinic,
} from '../../../shared/types/response/provider';
import { transformSlotsToWeekDay } from '../../common/Date';

export const useUpdateSlotsV1 = ({
  refreshData,
}: {
  refreshData: () => void;
}) =>
  useMutation({
    mutationFn: ({
      slotMap,
      clinicsList,
    }: {
      slotMap: Record<string, IProviderDaySlotV1>;
      clinicsList: InPersonClinic[];
    }) => {
      const body = transformSlotsToWeekDay(slotMap, clinicsList);
      return saveSlots(body);
    },
    onSuccess: (data) => {
      if (data.error) {
        toast.error(data.error.message);
      }
      refreshData();
    },
  });
