import Config from '../../../../shared/Config';
import NetworkClient from '../../../../shared/service/Network';
import { beautifyUrl } from '../../../../utilities/common/Path';
import { NoCancellationReqType } from '../../../view/app-components/CancellationFlow/types';
import {
  IAddProviderLeavesReqType,
  IAddProviderOverrideReqType,
  IRemoveProviderLeavesReqType,
} from '../../types/Availability';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to fetch client details, please try again!',
};

export const getProviderMeetings = async (
  fromDate: string,
  toDate: string,
  timezone: string,
  status?: string[],
) => {
  const response = await client.doGet(
    Config.apiV2.availability.getMeetings,
    {
      timezone,
    },
    { from: fromDate, to: toDate, status },
  );
  if (response && response.data) {
    return response?.data?.meetings;
  }

  throw new Error(errorMessages.generic);
};

export const cancelMeeting = async (
  meetingId: string,
  note: string,
  type?: string,
  otherReason?: string,
) => {
  const body = {
    options: {
      message: note,
    },
    reason: {
      type,
      otherReason,
    },
  };
  const response = await client.doPost(
    beautifyUrl(Config.api.sessions.cancelSession, [meetingId]),
    body,
  );
  if (response.error) {
    throw new Error(response.error?.message);
  }

  return response.data;
};

export const notifyNoCancellation = async (body: NoCancellationReqType) => {
  const response = await client.doPost(
    Config.api.cancellationFlows.noSessionCancellation,
    body,
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response.data.error?.message || errorMessages.generic);
};

export const getProviderAvailabilities = async () => {
  const response = await client.doGet(Config.apiV2.availability.availability);
  if (response && response?.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};

export const getProviderServicesAndClinics = async () => {
  const response = await client.doGet(
    Config.apiV2.availability.getServicesAndClinics,
  );
  if (response && response?.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};

export const getProviderOverrides = async (
  fromDate: string,
  toDate: string,
) => {
  const response = await client.doGet(
    Config.apiV2.availability.getOverrides,
    {},
    { from: fromDate, to: toDate },
  );
  if (response && response?.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};

export const addProviderOverride = async (payload: {
  overrides: IAddProviderOverrideReqType[];
}) => {
  const response = await client.doPost(
    Config.apiV2.availability.addOverride,
    payload,
  );
  if (response && response?.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};

export const removeProviderLeaves = async (
  payload: IRemoveProviderLeavesReqType,
) => {
  const response = await client.doPut(
    Config.apiV2.availability.removeLeaves,
    payload,
  );
  if (response && response?.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};

export const addProviderLeaves = async (payload: IAddProviderLeavesReqType) => {
  const response = await client.doPost(
    Config.apiV2.availability.addLeaves,
    payload,
  );
  if (response && response?.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};
