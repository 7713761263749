import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConfusedIcon } from '../../../assets/ConfusedIcon.svg';
import { ProviderRole } from '../../../shared/constant/Common';
import { ProviderSessionCancellationReasons } from '../../../shared/constant/Session';
import { Button } from '../../components/Button';
import Modal from '../../components/Modal';
import NoDataFound from '../../components/NoDataFound';
import { Select } from '../../components/Select';
import { ISessionListItem } from '../../screens/ClientDetails/types/session';
import MultilineInputWithCounter from '../../components/MultilineInputWithCounter';

interface IAvailabilitySessionCancellation {
  open: boolean;
  handleClose: () => void;
  session: ISessionListItem | null;
  onSubmit: (
    note: string,
    isNotify: boolean,
    type?: string,
    otherReason?: string,
  ) => void;
  loading: boolean;
}
function AvailabilitySessionCancellation({
  open,
  handleClose,
  session,
  onSubmit,
  loading,
}: IAvailabilitySessionCancellation) {
  const { t } = useTranslation();
  const isOnsiteSession = session?.providerRole === ProviderRole.ONSITE;
  const isInPersonSession = session?.providerRole === ProviderRole.CAREOFFSITE;

  const [cancellationReason, setCancellationReason] = useState(
    ProviderSessionCancellationReasons?.CANCEL_REASON_PROVIDER_OOO ?? '',
  );

  const [othersReason, setOthersReason] = useState('');

  const [cancellationNote, setCancellationNote] = useState('');

  const [modalTitles, setModalTitles] = useState<{
    title: string;
    subtitle: string;
  }>({ title: '', subtitle: '' });

  useEffect(() => {
    const titles =
      session && !isOnsiteSession && !isInPersonSession
        ? {
            title: 'Are you sure to cancel this session?',
            subtitle:
              "You won't be able to book another session with this client in the next 24 hours if you cancel the session",
          }
        : { title: '', subtitle: '' };

    setModalTitles(titles);
  }, [session, isOnsiteSession, isInPersonSession]);

  const CancellationReasonOptions = useMemo(
    () =>
      Object.keys(ProviderSessionCancellationReasons)?.map((item) => ({
        label: t(item),
        value: ProviderSessionCancellationReasons[item],
      })) ?? [],
    [],
  );

  const handleModalClose = () => {
    setCancellationNote('');
    setCancellationReason(
      ProviderSessionCancellationReasons?.CANCEL_REASON_PROVIDER_OOO ?? '',
    );
    setOthersReason('');
    handleClose();
  };

  const handleSubmit = () => {
    const isNotify =
      cancellationReason ===
      ProviderSessionCancellationReasons.CANCEL_REASON_PROVIDER_CLIENT_ASKED;

    onSubmit(cancellationNote, isNotify, cancellationReason, othersReason);
  };

  const modalContent = () => {
    if (isOnsiteSession || isInPersonSession) {
      // Note: for in-person sessions, support email is carenav@intellect.co
      return (
        <NoDataFound
          title={
            <span className="text-md font-semibold">
              Oops! You cannot edit this session
            </span>
          }
          description={`Please try reaching out to ${
            isOnsiteSession ? 'providers@intellect.co' : 'carenav@intellect.co'
          } to edit this session`}
          Icon={ConfusedIcon}
        />
      );
    }

    return (
      <div className="flex flex-col max-h-[60vh] gap-4 ">
        <div className="w-full flex flex-col gap-2 overflow-x-visible">
          <span className="text-sm text-gray-700 font-medium">
            Select a reason for cancellation
          </span>
          <Select
            selected={cancellationReason}
            options={CancellationReasonOptions}
            onSelect={(value) => setCancellationReason(value)}
            fullWidth
            className="min-w-0 max-w-[100%] text-sm"
          />
          {cancellationReason ===
            ProviderSessionCancellationReasons?.CANCEL_REASON_PROVIDER_OTHERS && (
            <>
              <span className="text-sm text-gray-700 font-medium">
                Others reason
              </span>
              <MultilineInputWithCounter
                inputData={othersReason}
                setInputData={setOthersReason}
                placeholder={t('OTHERS_REASON_SUBTEXT')}
                className="m-0"
                textAreaClassName="placeholder:text-xs"
                inputRows={1}
              />
            </>
          )}

          <span className="text-sm text-gray-700 font-medium">Add a note</span>
          <MultilineInputWithCounter
            inputData={cancellationNote}
            setInputData={setCancellationNote}
            placeholder="Write something here"
            className="m-0"
            textAreaClassName="placeholder:text-xs"
            inputRows={3}
          />
        </div>

        <hr />
        <Button
          onClick={handleSubmit}
          disabled={
            !cancellationReason ||
            (cancellationReason ===
              ProviderSessionCancellationReasons?.CANCEL_REASON_PROVIDER_OTHERS &&
              !othersReason) ||
            !cancellationNote ||
            loading
          }
        >
          {t('CANCEL_CTA')}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      handleClose={handleModalClose}
      className="w-[36vw]"
      title={modalTitles.title}
      subtitle={modalTitles.subtitle}
    >
      {modalContent()}
    </Modal>
  );
}

export default AvailabilitySessionCancellation;
