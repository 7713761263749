import * as yup from 'yup';

export const overridesValidationSchema = yup.object().shape({
  slotsRange: yup
    .array()
    .of(
      yup.object().shape({
        locationId: yup.string().required().nullable(),
        slots: yup.array().of(yup.number().required()).length(2).required(), // Enforces exactly 2 items
      }),
    )
    .required(),
  day: yup.string().required(),
});
