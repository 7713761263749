import { Switch as HeadlessSwitch } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

interface SwitchProps {
  checked?: boolean;
  onChange?: (newChecked: boolean) => void;
  className?: string;
}

export default function Switch({ checked, onChange, className }: SwitchProps) {
  return (
    <HeadlessSwitch
      checked={checked}
      onChange={onChange}
      className={twMerge(
        'group relative flex h-6  min-w-10 w-10 cursor-pointer rounded-full bg-gray-100 p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary-500',
        className,
      )}
    >
      <span
        aria-hidden="true"
        className="pointer-events-none inline-block size-4 translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-4"
      />
    </HeadlessSwitch>
  );
}
