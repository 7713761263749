import { Moment } from 'moment';
import { ISlotRange, MeetingCategories } from '../../shared/types/Availability';
import { MeetingStatus } from '../../shared/constant/Session';
import { MeetingEventVariants } from '../../shared/constant/Availability';
import { AvailabilityURLDateFormat } from './Date';

export function dateStringBuilder(startDate: Moment, endDate: Moment) {
  return {
    fromDate: startDate.format(AvailabilityURLDateFormat),
    toDate: endDate.add(1, 'd').format(AvailabilityURLDateFormat),
  };
}

export function getMeetingCategory(meetingProvideRole: string) {
  switch (meetingProvideRole) {
    case '10':
      return MeetingCategories.IN_PERSON;
    case '12':
      return MeetingCategories.ONSITE;
    case '0':
    case '1':
    default:
      return MeetingCategories.VIRTUAL;
  }
}

export function getStartEndDates(
  currentMomentDate: moment.Moment,
  slot: ISlotRange,
) {
  const timeRange = {
    startTime: slot?.slots?.[0] ?? '',
    endTime: slot?.slots?.[1] ? (slot?.slots?.[1] ?? 0) + 1 : '',
  };

  return {
    start: currentMomentDate
      .clone()
      .add(timeRange.startTime * 30, 'minutes')
      .toDate(),
    end: currentMomentDate
      .clone()
      .add(+(timeRange?.endTime ?? 1) * 30, 'minutes')
      .subtract(timeRange?.endTime === 48 ? 1 : 0, 'minutes')
      .toDate(),
  };
}

export const getMeetingStyles = (
  status: string,
  pastDatedVarient: 'past' | 'current',
  category: MeetingCategories,
) => {
  switch (status) {
    case MeetingStatus.requesttoschedule: {
      const styles = {
        background: 'bg-white border border-cyan-800 border-l-4 text-cyan-800',
        iconBg: 'bg-cyan-800',
        iconColor: 'text-white',
      };
      return styles;
    }

    case MeetingStatus.noshow:
    case MeetingStatus.usernoshow:
    case MeetingStatus.providernoshow: {
      const {
        background = '',
        iconBg = '',
        iconColor = '',
      } = category ? MeetingEventVariants[pastDatedVarient][category] : {};

      return { background: `${background} line-through`, iconBg, iconColor };
    }
    case MeetingStatus.cancelled:
    case MeetingStatus.userlastmincancelled:
    case MeetingStatus.providerlastmincancelled:
    case MeetingStatus.providercancelled:
    case MeetingStatus.autocancelled: {
      const styles = {
        background:
          'bg-error-300 border-l-4 border-l-error-400 text-white line-through',
        iconBg: 'bg-error-400',
        iconColor: 'text-white',
      };
      return styles;
    }

    case MeetingStatus.scheduled:
    case MeetingStatus.completed:
    default: {
      return MeetingEventVariants?.[pastDatedVarient]?.[category] ?? {};
    }
  }
};
