import { createContext } from 'react';
import {
  IClinic,
  IProviderDaySlot,
  IProviderTransformedOverrides,
} from '../../../../shared/types/Availability';

interface IAvailabilityContext {
  workingHoursDayWiseSlots?: IProviderDaySlot[];
  overriddenSlots?: IProviderTransformedOverrides[];
  blockedSlots?: IProviderTransformedOverrides[];
  holidays?: IProviderTransformedOverrides[];
  onsiteSlots?: IProviderTransformedOverrides[];
  clinics?: IClinic[];
  services?: string[];
  referchOverrides: Function;
}

const AvailabilityContext = createContext<IAvailabilityContext>(
  {} as IAvailabilityContext,
);

export default AvailabilityContext;
