export enum MeetingStatus {
  scheduled = '0',
  inprogress = '1',
  completed = '2',
  cancelled = '3',
  noshow = '4',
  empty = '5',
  userlastmincancelled = '6',
  providernoshow = '7',
  usernoshow = '8',
  requesttoschedule = '9',
  autocancelled = '10',
  providerlastmincancelled = '11',
  providercancelled = '12',
}

export enum MeetingType {
  twilio = '0',
  agora = '1',
  ms = '2',
  offline = '3',
  zoomLink = '4',
  daily = '5',
  zoom = '6',
}

export const MeetingStatusLabels = {
  [MeetingStatus.usernoshow]: 'CLIENT_NO_SHOW',
  [MeetingStatus.noshow]: 'NOSHOW',
  [MeetingStatus.providernoshow]: 'PROVIDER_NO_SHOW',
  [MeetingStatus.providercancelled]: 'CANCELLED',
  [MeetingStatus.cancelled]: 'CANCELLED',
  [MeetingStatus.providerlastmincancelled]: 'PROVIDER_LAST_MIN',
  [MeetingStatus.userlastmincancelled]: 'CLIENT_LAST_MIN',
  [MeetingStatus.autocancelled]: 'AUTO_CANCELLED',
};

export const ProviderSessionCancellationReasons: { [key: string]: string } = {
  CANCEL_REASON_PROVIDER_SCHEDULING_CONFLICT: '10',
  CANCEL_REASON_PROVIDER_PERSONAL: '11',
  CANCEL_REASON_PROVIDER_CLIENT_ASKED: '12',
  CANCEL_REASON_PROVIDER_CHANGE_DURATION: '13',
  CANCEL_REASON_PROVIDER_OOO: '15',
  CANCEL_REASON_PROVIDER_OTHERS: '14',
};
