import { useFetch, useMutation } from '../../../../../utilities/hooks/useFetch';
import {
  cancelMeeting,
  getProviderMeetings,
  notifyNoCancellation,
} from '../../../../shared/service/API/availability';
import { NoCancellationReqType } from '../../../app-components/CancellationFlow/types';
import { ISessionListItem } from '../../ClientDetails/types/session';

export function useProviderMeetings(
  fromDate: string,
  toDate: string,
  timezone: string,
  status?: string[],
) {
  return useFetch<ISessionListItem[]>(
    ['provider-availability-v2', fromDate, toDate, timezone, status],
    () => getProviderMeetings(fromDate, toDate, timezone, status),
    {
      enabled: Boolean(fromDate && toDate && timezone),
      refetchOnWindowFocus: false,
    },
  );
}

export function useCancellation({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: Error) => void;
}) {
  return useMutation(
    ({
      meetingId,
      note,
      type,
      otherReason,
    }: {
      meetingId: string;
      note: string;
      otherReason?: string;
      type?: string;
    }) => cancelMeeting(meetingId, note, type, otherReason),
    {
      onSuccess,
      onError,
    },
  );
}

export function useNotifyNoCancellation({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: Error) => void;
}) {
  return useMutation(
    (body: NoCancellationReqType) => notifyNoCancellation(body),
    {
      onSuccess,
      onError,
    },
  );
}
