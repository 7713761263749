import Config from '../../../../shared/Config';
import NetworkClient from '../../../../shared/service/Network';
import { beautifyUrl } from '../../../../utilities/common/Path';
import { getSlotsByWeekDay, getTimeZone } from '../../../utilities/common/Date';
import { virtualLocationKey } from '../../constant/Provider';
import { ISlots } from '../../types/Availability';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to fetch client sessions, please try again!',
};

export const getProviderWorkingHours = () =>
  client
    .doGet(Config.apiV2.availability.availability)
    .then((response) => {
      if (response && response.data) {
        const slotsByWeekDay = getSlotsByWeekDay(
          response.data?.slots || [],
          getTimeZone(),
        );

        Object.keys(slotsByWeekDay).forEach((day) => {
          const { slotsRange, ...rest } = slotsByWeekDay[day];
          slotsByWeekDay[day] = {
            ...rest,
            slotsRange: slotsRange
              .map((slotRange) => ({
                ...slotRange,
                locationId: slotRange.locationId || virtualLocationKey,
              }))
              .sort((a, b) => (a.slots[0] > b.slots[0] ? 1 : -1)),
          };
        });

        return slotsByWeekDay;
      }
      return {};
    })
    .catch((error) => {
      console.error('Error occurred while fetching provider slots - ', error);
    });

export const getproviderClientDetails = async (
  clientId: number,
  role: string,
) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.provider.getproviderClientDetails, [
      clientId.toString(),
    ]),
    {},
    {
      role,
    },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const saveWorkingHoursSlots = async (body: ISlots[]) =>
  client.doPost(Config.api.slots.getSlots, body);
