import * as React from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import './Layout.scss';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import useTracking from '../../../utilities/hooks/useTracking';
import LayoutContext from '../../../shared/context/LayoutContext';
import usePaths from '../../../utilities/hooks/usePaths';
import ChatFloatingButton from '../ChatFloatingButton';
import { IFloatingChatWindowSettings } from '../../../shared/types/Chat';
import { RootState } from '../../../shared/redux/reducers/rootReducer';
import {
  addItemToLocalStorage,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from '../../../utilities/common/Storage';
import { StorageItems } from '../../../shared/constant/App';
import FloatingChatWindowV2 from '../../../chat-module/view/app-components/FloatingChatWindowV2';
import { IHeaderSettings } from './types';
import InformationModal from '../InformationModal';
import watchIcon from '../../../assets/Watch.svg';
import {
  useProviderConfig,
  useUpdateProviderTimezone,
} from '../../../utilities/hooks/fetchHooks/provider';
import {
  getTimezoneInfo,
  isTimeZoneSame,
} from '../../../utilities/common/Date';
import useAuth from '../../../utilities/hooks/useAuth';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import { refreshApp } from '../../../utilities/common/Path';
import { FetchDevtools } from '../../../utilities/hooks/useFetch';
import { Sidebar } from '../../../v2/view/app-components/Sidebar';
import { Navbar } from '../../../v2/view/app-components/Navbar/Navbar';

function Layout() {
  const { user, isLoggedIn } = useAuth();

  const { track } = useTracking();
  const {
    data: providerConfigData,
    isLoading,
    refetch: refetchProviderConfig,
  } = useProviderConfig();
  const routeExceptionsForSectionComponents = [
    '/login',
    '/rooms',
    '/reset-password',
  ];
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [firstPath] = usePaths();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isExceptionPath, setExceptionPath] = React.useState<boolean>(true); // * isExceptionPath is for rerendering layout if the current screen doesn't require navbar or header
  const [floatingChatWindowSettings, setFloatingChatWindowSettings] =
    React.useState<IFloatingChatWindowSettings>({
      show: false,
      initialUser: null,
      viewMode: 'full-view',
    });
  const [, setShowPageNavigation] = React.useState<boolean>(true);
  const [, setPageNavigationSetting] = React.useState<IHeaderSettings>({
    setShowPageNavigation: (state) => setShowPageNavigation(state),
    onBackButtonClick: () => navigate(-1),
  });

  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(true);
  const [showTimeZoneChangePopUp, setShowTimeZoneChangePopUp] =
    React.useState(false);

  const { show, initialUser, viewMode } = floatingChatWindowSettings;
  const chatToken: string = useSelector(
    (state: RootState) => state.app.chatToken,
  );
  const cachedChatToken = getItemFromLocalStorage(
    StorageItems.CHAT_TOKEN,
    'string',
  ) as string;

  const updateChatWindowSettings = (
    chatWindowSettings: IFloatingChatWindowSettings,
  ) => {
    setFloatingChatWindowSettings((prev) => ({
      ...prev,
      ...chatWindowSettings,
    }));
  };

  const updateHeaderSettings = (headerSetting: Partial<IHeaderSettings>) => {
    setPageNavigationSetting((prev) => ({ ...prev, ...headerSetting }));
  };

  const contextValues = React.useMemo(
    () => ({
      layoutRef: parentRef,
      updateChatWindowSettings,
      chatWindowVisible: show,
      updateHeaderSettings,
      resetNavigation: () => {
        updateHeaderSettings({ onBackButtonClick: () => navigate(-1) });
      },
    }),
    [show],
  );

  React.useEffect(() => {
    if (routeExceptionsForSectionComponents.includes(firstPath)) {
      setExceptionPath(true);
    } else {
      setExceptionPath(false);
    }
  }, [firstPath]);

  const deviceTimeZone = moment.tz.guess(true);

  const { mutate: updateTimezone } = useUpdateProviderTimezone({
    onSuccess: (_, payload) => {
      refetchProviderConfig();
      addItemToLocalStorage('userTimeZone', payload.newTimezone);
      removeItemFromLocalStorage('userKeepExistingTimezone');
      refreshApp();
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  React.useEffect(() => {
    if (isLoading) return;
    let isTimezoneChanged =
      !!isLoggedIn &&
      !isTimeZoneSame(providerConfigData?.timezone as string, deviceTimeZone);
    if (
      deviceTimeZone ===
      getItemFromLocalStorage('userKeepExistingTimezone', 'string')
    ) {
      isTimezoneChanged = false;
    }
    setShowTimeZoneChangePopUp(isTimezoneChanged);
    if (isTimezoneChanged) {
      track(EventNames.timezone, {
        eventAction: EventActions.load,
        eventCategory: EventCategories.timezone,
        eventLabel: 'timezone',
        featureVersion: 'v1',
      });
    }
  }, [isLoading, providerConfigData, deviceTimeZone]);

  return (
    <div className="layout-container" ref={parentRef}>
      <LayoutContext.Provider value={contextValues}>
        {/* {show && <FloatingChatWindow show={show} initialUser={initialUser} viewMode={viewMode} />} */}
        {show && (
          <FloatingChatWindowV2
            show={show}
            initialUser={initialUser}
            viewMode={viewMode}
          />
        )}
        {!isExceptionPath && isLoggedIn ? (
          <Navbar
            handleOpenSidebar={() => setIsSidebarCollapsed(false)}
            currentTimeZone={providerConfigData?.timezone as string}
          />
        ) : null}
        {!isExceptionPath ? (
          <Sidebar
            collapsed={isSidebarCollapsed}
            handleCloseSidebar={() => setIsSidebarCollapsed(true)}
          />
        ) : null}
        {!isExceptionPath && (chatToken || cachedChatToken) && (
          <ChatFloatingButton />
        )}
        <div
          className={`main-content ${isExceptionPath ? 'no-page-margin' : ''} ${
            isLoggedIn ? '' : 'no-top-padding'
          }`}
        >
          <Outlet />
        </div>
        {!isExceptionPath && (
          <div>
            {createPortal(
              <InformationModal
                headerFontSize={16}
                imgSrc={watchIcon}
                fontSize={14}
                height="40px"
                padding="47px 24px 24px"
                headerText={t('TIMEZONE_CHANGE_HEADER', {
                  timezone: getTimezoneInfo({
                    timezone: deviceTimeZone,
                  }),
                })}
                bodyText={t('TIMEZONE_CHANGE_SUBTEXT')}
                onClose={() => {
                  setShowTimeZoneChangePopUp(false);
                  addItemToLocalStorage(
                    'userKeepExistingTimezone',
                    deviceTimeZone,
                  );
                  track(EventNames.timezone, {
                    eventAction: EventActions.click,
                    eventCategory: EventCategories.close,
                    eventLabel: 'close',
                    featureVersion: 'v1',
                  });
                }}
                show={showTimeZoneChangePopUp}
                customBtnContainerStyle="timezone-style"
                borderRadius="100px"
                leftBtnVariant="primary"
                leftBtnLabel={t('UPDATE_TIMEZONE')}
                rightBtnVariant="secondary"
                rightBtnLabel={t('KEEP_EXISTING')}
                textAlignment="center"
                rightBtnClick={() => {
                  setShowTimeZoneChangePopUp(false);
                  addItemToLocalStorage(
                    'userKeepExistingTimezone',
                    deviceTimeZone,
                  );
                  track(EventNames.timezone, {
                    eventAction: EventActions.click,
                    eventCategory: EventCategories.doNotChange,
                    eventLabel: 'do_not_change',
                    featureVersion: 'v1',
                  });
                }}
                leftBtnClick={() => {
                  updateTimezone({
                    providerId: user.id,
                    newTimezone: deviceTimeZone,
                  });
                  track(EventNames.timezone, {
                    eventAction: EventActions.click,
                    eventCategory: EventCategories.changeTimeZone,
                    eventLabel: 'change_timezone',
                    featureVersion: 'v1',
                  });
                }}
              />,
              document.getElementById('timezone-portal') as HTMLDivElement,
            )}
          </div>
        )}

        <FetchDevtools />
      </LayoutContext.Provider>
    </div>
  );
}

export default Layout;
