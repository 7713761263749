import { VirtualElement } from '@popperjs/core';
import { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import { ReactComponent as ConfusedIcon } from '../../../assets/ConfusedIcon.svg';
import { Button } from '../../components/Button';
import Modal from '../../components/Modal';
import NoDataFound from '../../components/NoDataFound';
import Popper from '../../components/Popper/Popper';
import { TabPanel, Tabs } from '../../components/Tabs';
import UnOrderedList from '../../components/UnOrderedList';
import AvailabilityAddLeave from './AvailabilityAddLeave';
import AvailabilityOverride from './AvailabilityOverride';
import {
  AvailabilityEventType,
  IAvailabilityEventItem,
} from '../../../shared/types/Availability';
import AvailabilityRemoveLeave from './AvailabilityRemoveLeave';

const TabsList = [
  { key: 1, label: 'Override' },
  { key: 2, label: 'Out of office' },
];

function AvailabilityEditSlotsPopup({
  anchorEl,
  handleClose,
  selectedDay,
  tab,
  event,
}: {
  anchorEl: Element | VirtualElement | null;
  selectedDay: string | null;
  handleClose: () => void;
  tab?: 'override' | 'out-of-office';
  event: IAvailabilityEventItem | null;
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [showDraftModal, setShowDraftModal] = useState(false);
  const [, setHasUnsavedChanges] = useState(false);
  const [, setShowingSubModal] = useState(false);

  useEffect(() => {
    setSelectedIndex(tab === 'override' ? 0 : 1);
  }, [tab]);

  const handleTabChange = (newIndex: number) => {
    setHasUnsavedChanges((prevHasUnsavedChanges) => {
      if (prevHasUnsavedChanges) {
        setShowDraftModal(true);
      } else {
        setSelectedIndex(newIndex);
      }
      return prevHasUnsavedChanges;
    });
  };

  const handlePopperClose = () => {
    // Added below check to prevent popper close on outside click, so if modal is alredy open then do nothing
    if (showDraftModal) return;

    setHasUnsavedChanges((prev) => {
      if (prev) {
        setShowDraftModal(true);
        return prev;
      }

      handleClose();
      return prev;
    });
  };

  const handleDraftModalClose = () => {
    setShowDraftModal(false);
  };

  return (
    <>
      {anchorEl && (
        <Popper
          anchorEl={anchorEl}
          handleClose={() => {
            setShowingSubModal((prev) => {
              if (prev) return prev;

              handlePopperClose();
              return prev;
            });
          }}
          placement="auto-end"
        >
          <div className="min-w-[32vw] max-w-[90vw] max-h-[90vh] rounded-2xl bg-white relative mx-2 shadow-xl p-4 flex flex-col gap-2">
            <div className="w-full flex items-start justify-between font-semibold leading-7 text-gray-900 w-100">
              <div className="w-full flex-8 flex gap-2 truncate">
                <div className="flex flex-col truncate min-w-0">
                  <span className="text-displayXs">Manage availability</span>
                  <UnOrderedList
                    className="text-sm"
                    items={[
                      {
                        label: 'Manage your availability from block',
                      },
                    ]}
                  />
                </div>
              </div>

              <Button
                variant="ghost"
                className="flex-2 w-8 h-8 p-2 focus:bg-gray-200"
                onClick={handlePopperClose}
                autoFocus={false}
              >
                <CloseIcon className="text-gray-600" />
              </Button>
            </div>

            <Tabs
              selectedIndex={selectedIndex}
              setSelectedIndex={handleTabChange}
              className={{
                tabGroupClass: 'text-gray-500',
                tabClass: 'w-28 mr-2 rounded-lg',
                selectedClass:
                  'bg-primary-50 text-primary-600 border border-primary-100 hover:bg-primary-200/50',
                deselectedClass: 'border-none bg-white hover:bg-gray-200/50',
              }}
              list={TabsList}
            >
              <TabPanel index={1} className="bg-white p-0 px-1">
                <AvailabilityOverride
                  selectedDay={selectedDay}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  handleClose={handleClose}
                />
              </TabPanel>
              <TabPanel index={1} className="bg-white p-0 px-1">
                {event?.type === AvailabilityEventType.LEAVE_SLOT ? (
                  <AvailabilityRemoveLeave
                    event={event}
                    handleClose={handleClose}
                  />
                ) : (
                  <AvailabilityAddLeave
                    setShowingSubModal={setShowingSubModal}
                    handleClose={handleClose}
                  />
                )}
              </TabPanel>
            </Tabs>
          </div>
        </Popper>
      )}

      <Modal
        open={showDraftModal}
        handleClose={handleDraftModalClose}
        showCloseButton={false}
        allowOutsideClick={false}
      >
        <section className="flex flex-col gap-4 w-[40vw]">
          <NoDataFound
            title="Discard unsaved changes?"
            description="All unsaved changes will be lost. This action cannot be undone."
            Icon={ConfusedIcon}
          />
          <hr />
          <div className="flex gap-4">
            <Button
              className="flex-1"
              variant="secondary"
              onClick={handleDraftModalClose}
            >
              Cancel
            </Button>
            <Button
              className="flex-1"
              onClick={() => {
                handleDraftModalClose();
                handleClose();
              }}
            >
              Discard
            </Button>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default AvailabilityEditSlotsPopup;
