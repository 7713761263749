import { twMerge } from 'tailwind-merge';

function AvailabilityLeaveHoursEvent() {
  return (
    <div className={twMerge('w-full h-full font-medium text-xs bg-gray-100')}>
      <div
        className="w-full h-full"
        style={{
          background:
            'repeating-linear-gradient(45deg, #D0D5DD,#D0D5DD  1px, transparent 1px, transparent 10px)',
        }}
      />
    </div>
  );
}

export default AvailabilityLeaveHoursEvent;
