import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentLocale } from '../../../../i18n';
import { ISlotTime } from '../../../../shared/types/Time';
import { generate30MinTimeIntervals } from '../../../../utilities/common/Date';
import { Select } from '../../components/Select';

export interface ITimeRange {
  startTime: string;
  endTime: string;
}

interface ITimeRangePicker {
  onChange: (time: ITimeRange) => void;
  value?: Partial<ITimeRange>;
  disabled?: boolean;
  selectClassName?: string;
}

function TimeRangePicker({
  onChange,
  value,
  disabled = false,
  selectClassName,
}: ITimeRangePicker) {
  const { t } = useTranslation();
  const locale = getCurrentLocale();
  const intervals: ISlotTime[] = [
    ...generate30MinTimeIntervals({
      is12HrFormat: locale.includes('en'),
      totalSlot: disabled ? 49 : 48,
    }),
  ];
  const [selectedTime, setSelectedTime] = React.useState<ITimeRange>({
    startTime: value?.startTime || '',
    endTime: value?.endTime || '',
  });
  const [startTimeIndex, setStartTimeIndex] = React.useState<number>(0);

  // sub-range of end time values, we don't want duplicate keys for empty option so we conditionally builds endTimeOptions array
  const endTimeOptions: ISlotTime[] = startTimeIndex
    ? [...intervals.slice(startTimeIndex)]
    : [...intervals.slice(startTimeIndex)];

  const updateTime = (newValue: string, source: 'start' | 'end') => {
    console.log('onslotchange inside before', selectedTime, newValue);

    if (source === 'start') {
      setSelectedTime({ ...selectedTime, startTime: newValue, endTime: '' });
      const selectedTimeIndex = intervals.findIndex(
        (interval) => interval.slot === Number(newValue),
      );
      setStartTimeIndex(selectedTimeIndex + 1);
    }
    if (source === 'end') {
      setSelectedTime({ ...selectedTime, endTime: newValue });
      onChange({ ...selectedTime, endTime: newValue });
    }
    console.log('onslotchange inside after', selectedTime);
  };

  React.useEffect(() => {
    if (value) {
      setSelectedTime({
        startTime: value?.startTime || '',
        endTime: value?.endTime || '',
      });
    }
  }, [value]);

  return (
    <div className="flex gap-4 items-center">
      <Select
        onSelect={(newValue) => updateTime(newValue, 'start')}
        selected={selectedTime.startTime}
        disabled={disabled}
        options={intervals
          .slice(0, intervals.length - 1)
          .map(({ slot, time }) => ({ value: slot.toString(), label: time }))}
        placeholder={t('Start time')}
        className={selectClassName}
      />
      <div>-</div>
      <Select
        onSelect={(newValue) =>
          updateTime((Number(newValue) - 1).toString(), 'end')
        }
        selected={
          selectedTime?.endTime
            ? (Number(selectedTime.endTime) + 1).toString()
            : ''
        }
        disabled={disabled || !selectedTime?.startTime}
        options={endTimeOptions.map(({ slot, time }) => ({
          value: slot.toString(),
          label: time,
        }))}
        placeholder={t('End time')}
        className={selectClassName}
      />
    </div>
  );
}

export default TimeRangePicker;
