import { useFetch, useMutation } from '../../../../../utilities/hooks/useFetch';
import {
  addProviderLeaves,
  addProviderOverride,
  getProviderAvailabilities,
  getProviderOverrides,
  getProviderServicesAndClinics,
  removeProviderLeaves,
} from '../../../../shared/service/API/availability';
import {
  IAddProviderLeavesReqType,
  IAddProviderOverrideReqType,
  IProviderAvailabilityOverrides,
  IProviderRawAvailability,
  IProviderServicesAndClinic,
  IRemoveProviderLeavesReqType,
} from '../../../../shared/types/Availability';

export function useProviderAvailabilities() {
  return useFetch<IProviderRawAvailability>(
    ['provider-v2-availabilities'],
    () => getProviderAvailabilities(),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useProviderServicesAndClinics() {
  return useFetch<IProviderServicesAndClinic>(
    ['provider-v2-services-and-clinics'],
    () => getProviderServicesAndClinics(),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useProviderOverrides(fromDate: string, toDate: string) {
  return useFetch<IProviderAvailabilityOverrides>(
    ['provider-v2-overrides', fromDate, toDate],
    () => getProviderOverrides(fromDate, toDate),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useAddOverride(
  handleSuccess: () => void,
  handleError: () => void,
) {
  return useMutation(
    (payload: IAddProviderOverrideReqType[]) =>
      addProviderOverride({ overrides: payload }),
    { onSuccess: handleSuccess, onError: handleError },
  );
}

export function useRemoveLeave(
  handleSuccess: () => void,
  handleError: () => void,
) {
  return useMutation(
    (payload: IRemoveProviderLeavesReqType) => removeProviderLeaves(payload),
    { onSuccess: handleSuccess, onError: handleError },
  );
}

export function useAddLeave(
  handleSuccess: () => void,
  handleError: () => void,
) {
  return useMutation(
    (payload: IAddProviderLeavesReqType) => addProviderLeaves(payload),
    { onSuccess: handleSuccess, onError: handleError },
  );
}
