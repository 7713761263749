import moment from 'moment';
import { useContext } from 'react';
import { toast } from 'sonner';
import {
  IAvailabilityEventItem,
  IProviderTransformedOverrides,
} from '../../../shared/types/Availability';
import {
  AvailabilityUpdateLeaveDateFormat,
  AvailabilityUpdateLeaveTimeFormat,
} from '../../../utilities/common/Date';
import { Button } from '../../components/Button';
import InfoTile from '../../components/InfoTile';
import AvailabilityContext from '../../screens/Availability/context/AvailabilityContext';
import { useRemoveLeave } from '../../screens/Availability/hooks/UseAvailabilities';

interface IAvailabilityRemoveLeave {
  event: IAvailabilityEventItem;
  handleClose: () => void;
}
function AvailabilityRemoveLeave({
  event,
  handleClose,
}: IAvailabilityRemoveLeave) {
  const { referchOverrides } = useContext(AvailabilityContext);

  // --------------- NETWORK CALLS ---------------
  const handleRemoveLeaveApiSuccess = () => {
    toast.success('Availability updated');
    handleClose();
    referchOverrides();
  };

  const handleRemoveLeaveApiError = () => {
    toast.error('Error in updating Availability');
  };

  const { mutate: removeLeave, isLoading: removeLeaveLoading } = useRemoveLeave(
    handleRemoveLeaveApiSuccess,
    handleRemoveLeaveApiError,
  );

  // --------------- DATA SETTERS & LISTENERS ---------------
  const startTime = moment(event?.start).format(
    AvailabilityUpdateLeaveTimeFormat,
  );

  const endTime = moment(event?.end).format(AvailabilityUpdateLeaveTimeFormat);

  const leaveTimings = `${moment(event?.start)
    .format(AvailabilityUpdateLeaveDateFormat)
    .toString()} (${startTime}-${endTime})`;

  // --------------- HANDLERS ---------------
  const handleMarkAsAvailable = () => {
    const leaveIds =
      (event?.slotInfo?.slot as IProviderTransformedOverrides)?.slotIds ?? [];

    removeLeave({ leaveIds });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex flex-col gap-2 min-h-[10vh] h-[40vh] overflow-y-scroll">
        <InfoTile
          variant="gray"
          className="bg-gray-25"
          contentClassName="text-sm text-gray-700"
          showIcon={false}
          content={leaveTimings}
        />
      </div>
      <hr />
      <div className="flex flex-col gap-2">
        <Button
          className="w-full"
          onClick={handleMarkAsAvailable}
          disabled={removeLeaveLoading}
        >
          Mark as available
        </Button>
      </div>
    </div>
  );
}

export default AvailabilityRemoveLeave;
