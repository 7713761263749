import { useFetch } from '../../../../../utilities/hooks/useFetch';
import { getProviderWorkingHours } from '../../../../shared/service/API/provider';
import { IProviderDaySlot } from '../../../../shared/types/Availability';

export function useProviderWorkingHours({
  onSuccess,
}: {
  onSuccess: (data: Record<string, IProviderDaySlot>) => void;
}) {
  return useFetch<Record<string, IProviderDaySlot>>(
    ['provider-working-hours'],
    () => getProviderWorkingHours(),
    {
      onSuccess,
      refetchOnWindowFocus: false,
    },
  );
}
