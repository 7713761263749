import { isEmpty } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { getCurrentLocale } from '../../../../i18n';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit.svg';
import { ReactComponent as RoomIcon } from '../../../assets/home.svg';
import { ReactComponent as LocationIcon } from '../../../assets/location.svg';
import { ReactComponent as PlaneIcon } from '../../../assets/Plane.svg';
import { ReactComponent as RedirectionIcon } from '../../../assets/slantingArrow.svg';
import { ReactComponent as VideoIcon } from '../../../assets/videoRecorder.svg';
import { ProviderRole } from '../../../shared/constant/Common';
import { openExternalUrl } from '../../../utilities/common/Path';
import { getVideoTagStyle } from '../../../utilities/common/Session';
import { Button } from '../../components/Button';
import DateBadge from '../../components/DateBadge';
import UnOrderedList, {
  UnorderedListProps,
} from '../../components/UnOrderedList';
import { ISessionListItem } from '../../screens/ClientDetails/types/session';

interface IConflictingSessionsProps {
  data: ISessionListItem;
  className?: string;
  handleCancellationClick: (session: ISessionListItem) => void;
}

function ConflictingSessions({
  className,
  data,
  handleCancellationClick,
}: IConflictingSessionsProps) {
  const { t } = useTranslation();

  const dispTime: Record<string, string> = {
    ja: `${moment(+data.scheduledStartTime * 1000).format('HH:mm')} - ${moment(
      +data.scheduledEndTime * 1000,
    ).format('HH:mm')}`,
    en: `${data.displayTime} - ${data.displayTimeEnd}`,
  };

  const sessionCardItems: UnorderedListProps['items'] = [
    {
      label: dispTime[getCurrentLocale()],
      startIcon: <ClockIcon className="w-3 h-3" />,
    },
    {
      label: getVideoTagStyle(data.providerRole, data.type).name,
      startIcon:
        data?.providerRole === ProviderRole.ONSITE ? (
          <PlaneIcon className="w-3 h-3" />
        ) : (
          <VideoIcon className="w-3 h-3" />
        ),
    },
  ];

  const sessionLocation: UnorderedListProps['items'] = isEmpty(data.location)
    ? []
    : [
        {
          label: data.location.room,
          startIcon: <RoomIcon className="w-3 h-3" />,
        },
        {
          label:
            data?.providerRole === ProviderRole.ONSITE
              ? data?.location.address
              : data.location.name,
          startIcon: <LocationIcon className="w-3 h-3" />,
          endIcon: (
            <RedirectionIcon
              stroke="#667085"
              onClick={() =>
                openExternalUrl(
                  data?.providerRole === ProviderRole.ONSITE
                    ? data?.location?.roomGuide
                    : data?.location?.redirectUrl,
                )
              }
            />
          ),
        },
      ];

  return (
    <section
      className={twMerge(
        'w-full bg-white p-4 box-border flex shadow-sm items-center justify-between border border-solid border-gray-200 rounded-lg ',
        className,
      )}
    >
      <section className="w-full box-border flex items-start gap-4">
        <DateBadge timeStamp={Number(data.scheduledStartTime)} />
        <section className="w-[95%] flex flex-col gap-2 ">
          <section className="w-full flex items-center gap-2 ">
            <section className="text-md font-semibold text-gray-700">
              {data.presentingIssue || t('NO_PI_SESSION')}
            </section>
          </section>
          <UnOrderedList
            items={sessionCardItems}
            className="leading-[12px] text-sm font-normal"
          />
          {sessionLocation.length ? (
            <UnOrderedList
              items={sessionLocation}
              className="leading-[12px] text-sm font-normal"
            />
          ) : null}
        </section>
      </section>
      <Button
        variant="ghost"
        className="border border-gray-300 p-[10px]"
        onClick={() => handleCancellationClick(data)}
      >
        <EditIcon className="text-gray-500 w-5 h-5" />
      </Button>
    </section>
  );
}

export default ConflictingSessions;
