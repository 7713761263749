import moment from 'moment';
import {
  Calendar,
  CalendarProps,
  Components,
  Formats,
  momentLocalizer,
  NavigateAction,
  SlotInfo,
  View,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { IAvailabilityEventItem } from '../../../shared/types/Availability';

const localizer = momentLocalizer(moment);

export interface AvailabilityCalendarProps
  extends Omit<CalendarProps<IAvailabilityEventItem>, 'localizer'> {
  date?: Date;
  defaultDate?: string | Date;
  view?: View;
  views?: View[];
  onNavigate?: (newDate: Date, view: View, action: NavigateAction) => void;
  onSelectSlot?: (slotInfo: SlotInfo) => void;
  onRangeChange?: (
    range:
      | Date[]
      | {
          start: Date;
          end: Date;
        },
    view?: View,
  ) => void;
  selectable?: boolean;
  backgroundEvents?: IAvailabilityEventItem[];
  components?: Components<IAvailabilityEventItem, object>;
  startAccessor?:
    | keyof IAvailabilityEventItem
    | ((event: IAvailabilityEventItem) => Date);
  endAccessor?:
    | keyof IAvailabilityEventItem
    | ((event: IAvailabilityEventItem) => Date);
  onSelectEvent?: (
    event: IAvailabilityEventItem,
    e: React.SyntheticEvent<HTMLElement>,
  ) => void;
  formats?: Formats;
  scrollToTime?: Date;
}

function AvailabilityCalendar(props: AvailabilityCalendarProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Calendar {...props} localizer={localizer} />;
}

export default AvailabilityCalendar;
