import { cloneDeep, isEqual } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useBlocker } from 'react-router-dom';

import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../../shared/constant/Analytics';
import useTracking from '../../../../utilities/hooks/useTracking';
import fetchClient from '../../../../utilities/hooks/useFetch/fetchClient';
import WorkingHoursDay from './WorkingHoursDay';
import { Button } from '../../components/Button';
import { ReactComponent as ChevronLeft } from '../../../assets/ChevronLeftV2.svg';
import { ReactComponent as ConfusedIcon } from '../../../assets/ConfusedIcon.svg';
import { DaysOfWeek } from '../../../shared/constant/Provider';
import {
  IProviderDaySlot,
  ISlotRange,
} from '../../../shared/types/Availability';
import { validateSlotsOverlap } from '../../../utilities/common/Date';
import { useProviderServicesAndClinics } from '../Availability/hooks/UseAvailabilities';
import { useUpdateWorkingHours } from './hooks/useUpdateWorkingHours';
import { useProviderWorkingHours } from './hooks/useProviderWorkingHours';
import ClientOverviewCardLoader from '../ClientDetails/ClientOverviewCardLoader';
import Modal from '../../components/Modal';
import NoDataFound from '../../components/NoDataFound';

export function WorkingHours() {
  const { t } = useTranslation();
  const { track } = useTracking();

  const [slots, setSlots] = useState<Record<string, IProviderDaySlot>>({});
  const [showDraftModal, setShowDraftModal] = useState(false);

  const [daysWithSlotConflicts, setDaysWithSlotConflicts] = useState<
    Record<string, number[]>
  >({
    '0': [],
    '1': [],
    '2': [],
    '3': [],
    '4': [],
    '5': [],
    '6': [],
  });

  const { data: fetchedSlots, isLoading } = useProviderWorkingHours({
    onSuccess: (data) => {
      setSlots(cloneDeep(data));
    },
  });

  const { data: providerServices, isLoading: providerServicesLoading } =
    useProviderServicesAndClinics();

  const { mutate: saveSlots } = useUpdateWorkingHours({
    onSuccess: () => fetchClient.invalidateQueries(['provider-working-hours']),
  });

  const onRemoveSlot = (day: string, removedSlotIndex: number) => {
    const slotsToUpdate = cloneDeep(slots) || {};
    slotsToUpdate[Number(day)].slotsRange = slotsToUpdate[
      Number(day)
    ].slotsRange.filter((_slot, index) => index !== removedSlotIndex);

    setSlots(slotsToUpdate);
  };

  console.log('current slots', slots);

  const onAddSlot = (day: string) => {
    let slotsToUpdate = cloneDeep(slots) || {};
    if (slotsToUpdate[Number(day)]) {
      slotsToUpdate[Number(day)].slotsRange.push({
        locationId: null,
        slots: [],
      });
    } else {
      slotsToUpdate = {
        ...slotsToUpdate,
        [day]: {
          dayDisplay: '',
          slots: {},
          slotsRange: [
            {
              locationId: null,
              slots: [],
            },
          ],
          day,
        },
      };
    }
    setSlots(slotsToUpdate);

    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.addTimeSlot,
      eventLabel: 'add_time_slot',
      featureVersion: 'v1',
    });
  };

  const onSlotsChange = (
    day: string,
    index: number,
    updatedSlot: ISlotRange,
  ) => {
    const slotsToUpdate = cloneDeep(slots) || {};

    if (slotsToUpdate[Number(day)].slotsRange.length > index) {
      // Check for overlaps between slots
      const isOverlapping = validateSlotsOverlap(
        updatedSlot,
        slotsToUpdate[Number(day)].slotsRange.filter(
          (_slot, idx) => idx !== index,
        ),
      );

      if (isOverlapping) {
        if (!daysWithSlotConflicts[day].includes(index)) {
          setDaysWithSlotConflicts((prev) => ({
            ...prev,
            [day]: [...prev[day], index],
          }));
        }
      } else if (daysWithSlotConflicts[day].includes(index)) {
        console.log('removing from overlap');
        setDaysWithSlotConflicts((prev) => ({
          ...prev,
          [day]: prev[day].filter((idx) => idx !== index),
        }));
      }

      slotsToUpdate[Number(day)].slotsRange[index] = { ...updatedSlot };
    }

    console.log(
      'onslotschange after',
      slotsToUpdate[Number(day)]?.slotsRange[index].slots,
    );
    setSlots(slotsToUpdate);
  };

  const onClearSlots = (day: string) => {
    const slotsToUpdate = cloneDeep(slots) || {};
    if (slotsToUpdate[Number(day)]) {
      delete slotsToUpdate[Number(day)];
      setSlots(slotsToUpdate);
    }
  };

  React.useEffect(() => {
    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.workingHours,
      eventLabel: 'working_hours',
      featureVersion: 'v1',
    });
  }, []);

  // Check if form is dirty i.e slots have been changed.
  const isFormDirty = !isEqual(fetchedSlots, slots);

  React.useEffect(() => {
    const handleBeforeUnload = (e: Event) => {
      if (isFormDirty) {
        e.preventDefault();
        return true; // Return a truthy value
      }
      return null; // Allow navigation if no conditions met
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isFormDirty]);

  const shouldBlockNavigation = () => {
    if (isFormDirty) {
      setShowDraftModal(true);
      return true;
    }
    return false; // Allow navigation
  };

  const blocker = useBlocker(shouldBlockNavigation);

  const shouldEnableSaveButton = () => {
    const slotRangesOfAllDays = Object.values(slots).map(
      (dayData) => dayData.slotsRange,
    );

    // Check if at least some slots exist and the form isn't empty.
    const doAtLeastSomeSlotsExist = slotRangesOfAllDays.some(
      (slotRange) => slotRange.length,
    );

    // Check if there are no slotRanges with empty slots and null locations.
    const doAllSlotsHaveCompleteData = slotRangesOfAllDays
      .filter((slotRange) => slotRange.length)
      .every((slotRange) =>
        slotRange.every(
          (slotObject) => !!slotObject.locationId && slotObject.slots.length,
        ),
      );

    // Check if all days are without any slot conflicts.
    const areAllDaysWithoutConflictingSlots = Object.values(
      daysWithSlotConflicts,
    ).every((dayConflicts) => !dayConflicts.length);

    return (
      isFormDirty &&
      doAtLeastSomeSlotsExist &&
      doAllSlotsHaveCompleteData &&
      areAllDaysWithoutConflictingSlots
    );
  };

  const handleDiscardChanges = () => {
    setShowDraftModal(false);
    blocker.proceed?.();
  };

  return (
    <div>
      <div className="px-5 py-3 flex items-center justify-between">
        <Link to="/availability">
          <Button variant="ghost">
            <ChevronLeft className="w-3 text-gray-400" />
          </Button>
        </Link>
        <Button
          onClick={() =>
            saveSlots({
              slotMap: slots,
              clinicsList: providerServices?.clinics || [],
            })
          }
          disabled={!shouldEnableSaveButton()}
          className="w-24"
        >
          {t('SAVE_CTA')}
        </Button>
      </div>
      <div className="mt-5 w-11/12 px-12 mx-auto">
        <div className="mb-5">
          <div className="text-displayXs font-semibold mb-1">Working Hours</div>
          <div className="text-sm text-gray-600">
            Manage and adjust your working hours for each day to fit your
            schedule.
          </div>
        </div>

        {providerServicesLoading ||
        !providerServices ||
        !fetchedSlots ||
        isLoading ? (
          <div className="flex flex-col gap-5">
            <ClientOverviewCardLoader variant="working-hour-slot" />
            <ClientOverviewCardLoader variant="working-hour-slot" />
            <ClientOverviewCardLoader variant="working-hour-slot" />
            <ClientOverviewCardLoader variant="working-hour-slot" />
          </div>
        ) : (
          <div>
            {Object.keys(DaysOfWeek).map((dayCode) => (
              <WorkingHoursDay
                dayCode={dayCode}
                key={`weekday-${dayCode}`}
                data={
                  slots?.[dayCode] || {
                    day: dayCode,
                    dayDisplay: '',
                    slots: {},
                    slotsRange: [],
                  }
                }
                onRemoveSlot={onRemoveSlot}
                onSlotsChange={onSlotsChange}
                onAddSlot={() => onAddSlot(dayCode)}
                onSlotsClear={onClearSlots}
                providerServices={providerServices}
                slotsWithConflicts={daysWithSlotConflicts[dayCode]}
              />
            ))}
          </div>
        )}
      </div>

      <Modal
        open={showDraftModal}
        handleClose={handleDiscardChanges}
        showCloseButton={false}
        allowOutsideClick={false}
      >
        <section className="flex flex-col gap-4 w-[40vw]">
          <NoDataFound
            title="Discard unsaved changes?"
            description="All unsaved changes will be lost. This action cannot be undone."
            Icon={ConfusedIcon}
          />
          <hr />
          <div className="flex gap-4">
            <Button
              className="flex-1"
              variant="secondary"
              onClick={() => setShowDraftModal(false)}
            >
              Cancel
            </Button>
            <Button className="flex-1" onClick={() => handleDiscardChanges()}>
              Discard
            </Button>
          </div>
        </section>
      </Modal>
    </div>
  );
}
