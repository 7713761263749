import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useLocation, Link } from 'react-router-dom';
import intellectLogo from '../../assets/IntellectLogo.svg';
import { ReactComponent as SidebarCloseIcon } from '../../assets/close.svg';
import { ReactComponent as AssignmentIcon } from '../../assets/assignment.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendarWithDate.svg';
import { ReactComponent as InsightIcon } from '../../assets/insightsLineChart.svg';
import { ReactComponent as PersonMultipleIcon } from '../../assets/personMultiple.svg';
import { ReactComponent as VideoIcon } from '../../assets/video-recorder.svg';
import { ReactComponent as LinkIcon } from '../../assets/linkArrow.svg';
import { ReactComponent as BillingIcon } from '../../assets/billingWallet.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';
import { Button } from '../components/Button';
import { getCurrentLocale } from '../../../i18n';

const sidebarItems = [
  {
    id: 1,
    label: 'SESSIONS',
    route: '/sessions',
    icon: <VideoIcon />,
    instrumentation: {
      eventName: EventNames.upcomingSessions,
      eventAction: EventActions.click,
      eventCategory: EventCategories.upcomingSessions,
      eventLabel: 'upcoming_sessions',
      featureVersion: 'v1',
    },
  },
  {
    id: 2,
    label: 'AVAILABILITY',
    route: '/schedule',
    icon: <CalendarIcon />,
    instrumentation: {
      eventName: EventNames.workingHours,
      eventAction: EventActions.click,
      eventCategory: EventCategories.schedule,
      eventLabel: 'schedule',
      featureVersion: 'v1',
    },
  },
  {
    id: 2.1,
    label: 'AVAILABILITY',
    route: '/availability',
    icon: <CalendarIcon />,
  },
  {
    id: 3,
    label: 'clients',
    route: '/clients',
    icon: <PersonMultipleIcon />,
  },
  {
    id: 4,
    label: 'assignments',
    route: '/assignments',
    icon: <AssignmentIcon />,
  },
  {
    id: 5,
    label: 'INSIGHTS',
    route: '/insights',
    icon: <InsightIcon />,
  },
  {
    id: 6,
    label: 'billingHeader',
    route: '/billing',
    icon: <BillingIcon />,
  },
  {
    id: 7,
    label: 'SETTINGS_TAB',
    route: '/settings',
    icon: <SettingsIcon />,
    instrumentation: {
      eventName: EventNames.settings,
      eventAction: EventActions.click,
      eventCategory: EventCategories.settings,
      eventLabel: 'settings',
      featureVersion: 'v1',
    },
  },
] satisfies {
  id: number;
  label: string;
  route: string;
  icon: JSX.Element;
  instrumentation?: {
    eventName: string;
    eventAction: string;
    eventCategory: string;
    eventLabel: string;
    featureVersion?: string;
  };
}[];

export const externalUrls = [
  {
    label: 'PROVIDER_HANDBOOK',
    link: {
      en: 'https://www.notion.so/intellecthq/Intellect-Provider-Handbook-3d8a06bd067e4969b248b0169468a52f',
      ja: 'https://intellecthq.notion.site/fb5e4bfdb4594f7cbe5f0c02b64e4ba7',
    },
  },
  {
    label: 'TALENT_LMS',
    link: {
      en: 'https://intellectco.talentlms.com/',
      ja: 'https://japan-intellectco.talentlms.com/index',
    },
  },
] as const;

interface SideNavBarProps {
  collapsed: boolean;
  handleCloseSidebar: () => void;
}

export function Sidebar({ collapsed, handleCloseSidebar }: SideNavBarProps) {
  const { t } = useTranslation();
  const currentLanguage =
    getCurrentLocale() as keyof (typeof externalUrls)[number]['link'];
  const location = useLocation();
  return (
    <>
      <div
        role="button"
        tabIndex={0}
        aria-label="sidebar backdrop"
        onClick={handleCloseSidebar}
        className={twMerge(
          'fixed overflow-hidden top-0 cursor-pointer opacity-0 left-0 h-screen w-screen bg-black z-[9] transition-all',
          collapsed ? 'invisible' : 'visible opacity-50',
        )}
      />
      <div
        style={{ fontFamily: 'Inter, sans-serif' }} // temporary till we change the global font
        className={twMerge(
          'fixed lg:top-[3.75rem] top-0 left-0 h-full w-60 bg-white z-10 transition-transform duration-500',
          collapsed
            ? 'sm:-translate-x-full lg:translate-x-0'
            : 'sm:translate-x-0',
        )}
      >
        <div className="p-5 lg:hidden flex items-center justify-between w-full gap-2">
          <img
            src={intellectLogo}
            alt="intellect logo"
            className="w-[7.5rem]"
          />
          {collapsed ? null : (
            <button type="button" onClick={handleCloseSidebar}>
              <SidebarCloseIcon className="text-gray-500 h-4 w-4" />
            </button>
          )}
        </div>

        <div className="flex flex-col gap-2 w-full p-5">
          {sidebarItems.map((sidebarItem) => (
            <Link
              to={sidebarItem.route}
              onClick={() => handleCloseSidebar()}
              className="underline-offset-1"
            >
              <Button
                key={`${sidebarItem.label}-${sidebarItem.route}`}
                variant="secondary"
                startIcon={sidebarItem.icon}
                className={twMerge(
                  'text-left font-medium w-full',
                  location.pathname.includes(sidebarItem.route)
                    ? 'border border-primary-100 text-primary-600 bg-primary-50'
                    : 'border-none',
                )}
              >
                {t(sidebarItem.label, {
                  version: sidebarItem.id === 2.1 ? '(v2)' : '',
                })}
              </Button>
            </Link>
          ))}
        </div>
        <div className="p-5">
          <div className="text-gray-400 mb-3">{t('RESOURCES')}</div>
          <div className="flex flex-col gap-2 w-full">
            {externalUrls.map((externalUrl) => (
              <a
                href={externalUrl.link?.[currentLanguage]}
                target="_blank"
                className="hover:no-underline"
                rel="noreferrer"
              >
                <Button
                  key={`${externalUrl.label}-${externalUrl.link}`}
                  variant="secondary"
                  startIcon={<LinkIcon />}
                  className="border-0 font-medium"
                >
                  {t(externalUrl.label)}
                </Button>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
