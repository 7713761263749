import moment from 'moment';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as MeetingInPersonIcon } from '../../../assets/MeetingInPerson.svg';
import { ReactComponent as MeetingOnsiteIcon } from '../../../assets/MeetingOnsite.svg';
import { ReactComponent as MeetingVirtualIcon } from '../../../assets/MeetingVirtual.svg';
import {
  IAvailabilityEventItem,
  MeetingCategories,
} from '../../../shared/types/Availability';
import { getMeetingStyles } from '../../../utilities/common/Availability';

function AvailabilityEvent({ event }: { event: IAvailabilityEventItem }) {
  const MeetingIcon = useMemo(() => {
    switch (event?.meeting?.category) {
      case MeetingCategories.ONSITE:
        return MeetingOnsiteIcon;
      case MeetingCategories.IN_PERSON:
        return MeetingInPersonIcon;
      case MeetingCategories.VIRTUAL:
      default:
        return MeetingVirtualIcon;
    }
  }, [event]);

  const pastDatedVarient = moment(event?.start).isBefore(moment.now())
    ? 'past'
    : 'current';

  const {
    background = '',
    iconBg = '',
    iconColor = '',
  } = getMeetingStyles(
    event?.meeting?.status ?? '',
    pastDatedVarient,
    event?.meeting?.category ?? MeetingCategories.VIRTUAL,
  );

  return (
    <div className="block w-full h-full border border-white rounded">
      <div
        role="button"
        tabIndex={0}
        className={twMerge(
          'w-full h-full rounded font-medium text-xs border-l-4 flex flex-col p-1.5 overflow-hidden',
          background,
          // 'focus:outline focus:outline-[#1570ef]', // outline if needed
          'focus:shadow-md',
        )}
      >
        <div className="flex gap-1 items-center justify-between">
          <span className="text-xs font-semibold truncate">
            {event?.meeting?.participantName}
          </span>

          <div
            className={twMerge(
              'rounded-full min-w-3 h-3 flex items-center justify-center',
              iconBg,
            )}
          >
            <MeetingIcon className={iconColor} />
          </div>
        </div>

        <span className="text-xs font-medium truncate">
          {moment(event?.start).format('HH:mm A')?.toString()}
        </span>
      </div>
    </div>
  );
}

export default AvailabilityEvent;
