import moment from 'moment';
import { motion } from 'framer-motion';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { ReactComponent as CalendarIcon } from '../../../assets/CalendarWithoutDate.svg';
import { ReactComponent as ObserveIcon } from '../../../assets/ObserveIcon.svg';
import {
  AvailabilityAPIDateFormat,
  AvailabilityUpdateLeaveDateFormat,
  getTimeZone,
} from '../../../utilities/common/Date';
import { Button } from '../../components/Button';
import { DatePicker } from '../../components/DatePicker';
import InfoTile from '../../components/InfoTile';
import Modal from '../../components/Modal';
import {
  useCancellation,
  useNotifyNoCancellation,
  useProviderMeetings,
} from '../../screens/Availability/hooks/UseMeetings';
import { ISessionListItem } from '../../screens/ClientDetails/types/session';
import AvailabilitySessionCancellation from './AvailabilitySessionCancellation';
import ConflictingSessions from './ConflictingSessions';
import { MeetingStatus } from '../../../shared/constant/Session';
import { useAddLeave } from '../../screens/Availability/hooks/UseAvailabilities';
import AvailabilityContext from '../../screens/Availability/context/AvailabilityContext';
import NoDataFound from '../../components/NoDataFound';

const ProviderTimezone = getTimeZone();

function AvailabilityAddLeave({
  setShowingSubModal,
  handleClose,
}: {
  setShowingSubModal: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
}) {
  const { t } = useTranslation();
  const { referchOverrides } = useContext(AvailabilityContext);

  // --------------- STATE MANAGEMENT ---------------
  const [showDatePicker, setShowDatePicker] = useState<{
    open: boolean;
    source: 'fromDate' | 'toDate';
  }>({ open: false, source: 'fromDate' });

  const [showCancellationModal, setShowCancellationModal] = useState<{
    open: boolean;
    session: ISessionListItem | null;
  }>({ open: false, session: null });

  const [showCancellationSuccessModal, setShowCancellationSuccessModal] =
    useState(false);

  const [selectedDateInDatePicker, setSelectedDateInDatePicker] = useState<
    Date | undefined
  >();

  const [leaveDate, setLeaveDate] = useState<{
    fromDate?: Date;
    toDate?: Date;
  }>();

  // --------------- NETWORK CALLS ---------------

  // only fetching pending & confirmed sessions
  const {
    data: providerMeetings,
    isLoading: meetingsLoading,
    refetch: refetchSessions,
  } = useProviderMeetings(
    leaveDate?.fromDate
      ? moment(leaveDate?.fromDate).format(AvailabilityAPIDateFormat).toString()
      : '',
    leaveDate?.toDate
      ? moment(leaveDate?.toDate)
          .add(1, 'day')
          .format(AvailabilityAPIDateFormat)
          .toString() // Adding 1 day to consider as end of toDate
      : '',
    ProviderTimezone ?? '',
    [MeetingStatus.scheduled, MeetingStatus.requesttoschedule],
  );

  const handleCloseCancellationModal = () =>
    setShowCancellationModal((prev) => ({ ...prev, open: false }));

  const notifyNoCancelSuccess = () => {
    toast.success(
      t('USER_NOTIFICATION', {
        name: showCancellationModal?.session?.friendlyName ?? '',
      }),
    );
    refetchSessions();
    handleCloseCancellationModal();
  };

  const notifyNoCancelError = (err: Error) => {
    toast.error(err?.message);
  };

  const { mutate: notifyNoCancel, isLoading: notifyUserLoading } =
    useNotifyNoCancellation({
      onSuccess: notifyNoCancelSuccess,
      onError: notifyNoCancelError,
    });

  const { mutate: cancelSession, isLoading: cancellationLoading } =
    useCancellation({
      onSuccess: () => {
        refetchSessions();
        handleCloseCancellationModal();
        setShowCancellationSuccessModal(true);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const handleAddLeaveApiSuccess = () => {
    toast.success('Availability updated');
    referchOverrides();
    handleClose();
  };

  const handleAddLeaveApiError = () => {
    toast.error('Error in updating Availability');
  };

  const { mutate: addLeave, isLoading: AddLeaveLoading } = useAddLeave(
    handleAddLeaveApiSuccess,
    handleAddLeaveApiError,
  );

  // --------------- DATA SETTERS & LISTENERS ---------------

  useEffect(() => {
    setShowingSubModal(showDatePicker.open);
  }, [showDatePicker]);

  useEffect(() => {
    setShowingSubModal(showCancellationModal.open);
  }, [showCancellationModal]);

  useEffect(() => {
    setShowingSubModal(showCancellationSuccessModal);
  }, [showCancellationSuccessModal]);

  // --------------- HANDLERS ---------------

  const handleCancellationClick = (session: ISessionListItem) => {
    setShowCancellationModal({ open: true, session });
  };

  const handleCloseDatePickerModal = () =>
    setShowDatePicker({ open: false, source: 'fromDate' });

  const handleCloseSessionCancellationSuccessModal = () =>
    setShowCancellationSuccessModal(false);

  const handleAddLeaveSubmit = () => {
    const { fromDate, toDate } = leaveDate ?? {};

    if (providerMeetings?.length || !fromDate || !toDate) return;

    addLeave({
      from: moment(fromDate).startOf('day').unix(),
      to: moment(toDate).endOf('day').unix(),
    });
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="w-full flex flex-col gap-2 min-h-[10vh] h-[40vh] overflow-y-scroll">
          <div className="flex gap-4">
            <div className="flex-1">
              <span className="text-sm text-gray-700 font-medium">From</span>
              <Button
                variant="secondary"
                onClick={() =>
                  setShowDatePicker({ open: true, source: 'fromDate' })
                }
                className="w-full text-start font-medium mt-1"
                startIcon={<CalendarIcon className="text-gray-400" />}
              >
                {leaveDate?.fromDate
                  ? moment(leaveDate?.fromDate).format(
                      AvailabilityUpdateLeaveDateFormat,
                    )
                  : null}
              </Button>
            </div>
            <div className="flex-1">
              <span className="text-sm text-gray-700 font-medium">To</span>

              <Button
                variant="secondary"
                onClick={() =>
                  setShowDatePicker({ open: true, source: 'toDate' })
                }
                className="w-full text-start font-medium mt-1"
                startIcon={<CalendarIcon className="currentColor" />}
                disabled={!leaveDate?.fromDate}
              >
                {leaveDate?.toDate
                  ? moment(leaveDate?.toDate).format(
                      AvailabilityUpdateLeaveDateFormat,
                    )
                  : null}
              </Button>
            </div>
          </div>

          {providerMeetings?.length ? (
            <div className="w-full flex flex-col gap-2">
              <span className="text-sm text-gray-700 font-medium">
                Conflicting sessions
              </span>
              <InfoTile
                variant="gray"
                contentClassName="text-gray-900"
                content="We suggest you reschedule or cancel your sessions before
                  marking OOO"
                showIcon
              />
              {providerMeetings?.map((session) => (
                <ConflictingSessions
                  data={session}
                  key={session?.id}
                  handleCancellationClick={handleCancellationClick}
                />
              ))}
            </div>
          ) : null}
        </div>

        <hr />
        <Button
          className="w-full"
          disabled={
            !leaveDate?.fromDate ||
            !leaveDate?.toDate ||
            meetingsLoading ||
            Boolean(providerMeetings?.length) ||
            AddLeaveLoading
          }
          onClick={handleAddLeaveSubmit}
        >
          Update availability
        </Button>
      </div>

      <Modal
        handleClose={handleCloseDatePickerModal}
        open={showDatePicker.open}
        title={t('SELECT_DATES_HEADER')}
      >
        <DatePicker
          selected={selectedDateInDatePicker}
          onSelect={setSelectedDateInDatePicker}
          classNames={{
            root: 'bg-transparent text-sm',
            day: '[&_button]:w-10 [&_button]:h-10 bg-transparent rounded-[50%]',
            disabled: 'text-grayModern-100',
          }}
          disabled={{
            before:
              showDatePicker.source === 'fromDate'
                ? moment().startOf('day').add(2, 'd').toDate()
                : moment(leaveDate?.fromDate).startOf('day').toDate(),
            after:
              showDatePicker.source === 'fromDate'
                ? undefined
                : moment(leaveDate?.fromDate)
                    .startOf('day')
                    .add(6, 'd')
                    .toDate(),
          }}
        />
        <Button
          variant="secondary"
          className="w-full text-start font-medium pointer-events-none my-4"
        >
          {selectedDateInDatePicker
            ? moment(selectedDateInDatePicker).format('DD MMMM, YYYY - dddd')
            : null}
        </Button>
        <Button
          className="mt-4 w-full"
          onClick={() => {
            if (!selectedDateInDatePicker) return;

            if (showDatePicker.source === 'fromDate') {
              setLeaveDate({
                toDate: undefined,
                [showDatePicker.source]: selectedDateInDatePicker,
              });
            } else {
              setLeaveDate((prev) => ({
                ...prev,
                [showDatePicker.source]: selectedDateInDatePicker,
              }));
            }

            handleCloseDatePickerModal();
          }}
          disabled={!selectedDateInDatePicker}
        >
          {t('SELECT_DATE_CTA')}
        </Button>
      </Modal>

      <AvailabilitySessionCancellation
        open={showCancellationModal.open}
        handleClose={handleCloseCancellationModal}
        session={showCancellationModal.session}
        onSubmit={(note, isNotify, type, otherReason) => {
          const session = showCancellationModal?.session;

          if (!session) return;

          if (!isNotify) {
            cancelSession({
              meetingId: session?.id,
              note,
              type,
              otherReason,
            });
          }
          if (isNotify) {
            notifyNoCancel({
              userId: Number(session?.userId),
              providerRole: session?.providerRole,
              message: note,
            });
          }
        }}
        loading={cancellationLoading || notifyUserLoading}
      />

      <Modal
        handleClose={handleCloseSessionCancellationSuccessModal}
        open={showCancellationSuccessModal}
        className="w-[36vw]"
        allowOutsideClick={false}
      >
        <div className="flex flex-col gap-4">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            transition={{ duration: 0.4 }}
          >
            <NoDataFound
              Icon={ObserveIcon}
              title="Your session is cancelled"
              description="Take care, and we’ll be here when you need us"
            />
          </motion.div>
          <hr />
          <Button onClick={handleCloseSessionCancellationSuccessModal}>
            Okay
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AvailabilityAddLeave;
