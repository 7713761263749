import { twMerge } from 'tailwind-merge';

function AvailabilityAdminBlockHoursEvent() {
  return (
    <div className={twMerge('w-full h-full font-medium text-xs bg-error-50')}>
      <div
        className="w-full h-full"
        style={{
          background:
            'repeating-linear-gradient(45deg, #FECDCA,#FECDCA  1px, transparent 1px, transparent 10px)',
        }}
      />
    </div>
  );
}

export default AvailabilityAdminBlockHoursEvent;
