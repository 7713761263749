import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { View, Views } from 'react-big-calendar';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Select } from '../../components/Select';

interface IAvailabilityActionsBar {
  selectedCalendarView: View;
  setSelectedCalendarView: Dispatch<SetStateAction<View>>;
  setCalendarSelectedDate: Dispatch<SetStateAction<Date>>;
}
function AvailabilityActionsBar({
  selectedCalendarView,
  setSelectedCalendarView,
  setCalendarSelectedDate,
}: IAvailabilityActionsBar) {
  const handleTodayBtnClick = () => setCalendarSelectedDate(moment().toDate());

  const handleViewChange = (data: string) =>
    setSelectedCalendarView(data as View);

  return (
    <section className="w-full flex">
      <div className="flex-1 flex flex-col">
        <span className="text-displayXs font-semibold">Availability</span>
        <span className="text-sm font-normal text-gray-600 max-w-[80%]">
          Manage your time easily! View your upcoming sessions and available
          slots, and make changes with ease
        </span>
      </div>
      <div className="flex-1 flex gap-2 justify-end">
        <Button variant="secondary" onClick={handleTodayBtnClick}>
          Today
        </Button>
        <Select
          options={[
            { label: 'Week', value: Views.WEEK },
            { label: 'Day', value: Views.DAY },
          ]}
          selected={selectedCalendarView}
          onSelect={handleViewChange}
        />
        <Link to="/working-hours">
          <Button variant="primary">Working hours</Button>
        </Link>
      </div>
    </section>
  );
}

export default AvailabilityActionsBar;
