import { t } from 'i18next';
import moment from 'moment';
import { ProviderRole } from '../../shared/constant/Common';
import { virtualLocationKey as VirtualLocationKey } from '../../shared/constant/Provider';
import { IClinic } from '../../shared/types/Availability';
import { ReactComponent as ClockIcon } from '../../assets/clock.svg';

export const getSessionTypeOptions = ({
  services = [],
  clinics = [],
  dayCode,
}: {
  services?: string[];
  clinics: IClinic[];
  dayCode: string;
}) => {
  let options = [];
  const servicesIncluded = Object.values(ProviderRole)
    .filter((role) => role !== ProviderRole.CAREOFFSITE)
    .some((element) => services.includes(element));
  if (servicesIncluded) {
    options.push({ label: t('VIRTUAL'), value: VirtualLocationKey });
  }

  if (services.includes(ProviderRole.CAREOFFSITE)) {
    options = [
      ...options,
      ...clinics.map((clinic) => {
        const clinicTiming = clinic.timing?.[moment(dayCode).day()];
        const timingString = clinicTiming
          ? `${clinicTiming[0] ?? ''}-${clinicTiming[1] ?? ''}`
          : 'Unavailable';
        return {
          label: clinic.name,
          value: clinic.id,
          description: (
            <span className="flex items-center gap-1 min-w-0">
              <ClockIcon className="w-3 h-3" />
              <span className="truncate">{timingString}</span>
            </span>
          ),
        };
      }),
    ];
  }

  return options;
};
