import { BlockingSlotsTypes, MeetingCategories } from '../types/Availability';
import { infoTileVariants } from './Colors';

export const MeetingEventVariants = {
  current: {
    [MeetingCategories.VIRTUAL]: {
      background: 'bg-cyan-200 text-cyan-800 border-l-cyan-500',
      iconBg: 'bg-cyan-800',
      iconColor: 'text-cyan-200',
    },
    [MeetingCategories.IN_PERSON]: {
      background: 'bg-violet-200 text-violet-800 border-l-violet-500',
      iconBg: 'bg-violet-800',
      iconColor: 'text-violet-200',
    },
    [MeetingCategories.ONSITE]: {
      background: 'bg-warning-200 text-warning-800 border-l-warning-500',
      iconBg: 'bg-warning-800',
      iconColor: 'text-warning-200',
    },
  },
  past: {
    [MeetingCategories.VIRTUAL]: {
      background: 'bg-cyan-100 text-cyan-500 border-l-cyan-300',
      iconBg: 'bg-cyan-500',
      iconColor: 'text-cyan-100',
    },
    [MeetingCategories.IN_PERSON]: {
      background: 'bg-violet-100 text-violet-500 border-l-violet-300',
      iconBg: 'bg-violet-500',
      iconColor: 'text-violet-100',
    },
    [MeetingCategories.ONSITE]: {
      background: 'bg-warning-100 text-warning-500 border-l-warning-300',
      iconBg: 'bg-warning-500',
      iconColor: 'text-warning-100',
    },
  },
};

export const AvailabilityBlockingVariants: Record<
  string,
  {
    variant: keyof typeof infoTileVariants;
    background: string;
    text: string;
    description: string;
  }
> = {
  [BlockingSlotsTypes.ADMIN_BLOCK]: {
    variant: 'error',
    background: 'border border-error-300',
    text: 'text-error-600',
    description: 'Blocked from',
  },
  [BlockingSlotsTypes.LEAVE]: {
    variant: 'gray',
    background: 'border border-gray-300',
    text: 'text-gray-600',
    description: 'Leave from',
  },
  [BlockingSlotsTypes.ONSITE]: {
    variant: 'warning',
    background: 'border border-warning-300',
    text: 'text-warning-600',
    description: 'Onsite Event from',
  },
};
